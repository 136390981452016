/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Progress, Row, Select, Table, Tooltip, notification } from "antd";
import { Carousel } from "react-carousel-minimal";
import { BsArrowDownCircle, BsArrowDownRightCircle, BsArrowRightCircle, BsArrowUpCircle, BsArrowUpRightCircle, BsDot } from "react-icons/bs";
import { IoMdImages } from "react-icons/io";
import { MdLocationPin, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { BiExpand } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import IMAGES from "../../../../../assets/images";
import ValidationResults from "./component/Dashboard/ValidationResults";
import SensitiveAnalysisCard from "./component/Dashboard/SensitiveAnalysisCard";
import LeaseAnalysisChart from "./component/Dashboard/LeaseAnalysisChart";
import AreaIncomeAnalysis from "./component/Dashboard/AreaIncomeAnalysis";
import TanentsAnalysis from "./component/Dashboard/TanentsAnalysis";
import CashFlowChart from "./component/Dashboard/CashFlowChart";
import FinancialKeyFigures from "./component/Dashboard/FinancialKeyFigures";
import RetailBuildingCard from "./component/Dashboard/RetailBuildingCard";
import ShowMapModal from "./component/Dashboard/ShowMapModal";
import ImagePreview from "./component/Dashboard/ImagePreview";
import { getByTypeDropdown } from "../../../../../app/features/Lookups/lookup.slice";
import {
  AssetTerminalValueCalulation,
  SaveAssetTerminalValueCalulation,
} from "../../../../../app/features/CorporateDashboard/CorporateDashboard.slice";
import { SlArrowDownCircle } from "react-icons/sl";
import moment from "moment/moment";
import { defaultStyles, JsonView } from "react-json-view-lite";
import Sticky from "react-stickynode";
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from "../../../../../utils/digitHelper";
import { LuRefreshCw } from "react-icons/lu";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FaExpandAlt } from "react-icons/fa";
import AccquisitionCostsSummary from "./component/Dashboard/AccquisitionCostsSummary";
import AccquisitionCostsExitSummary from "./component/Dashboard/AccquisitionCostsExitSummary";
import AssetDetailsTables from "./component/Dashboard/AssetDetailsTables";
import TenantAnalysis from "./component/Dashboard/TenantAnalysis";
import LoanCost from "./component/Dashboard/LoanCost";
import CashFlowCharts from "./component/Dashboard/CashFlowCharts";
import Chart from 'react-apexcharts';
import dayjs from "dayjs";

const rentalAnalysisIcons = {
  '-100%': { text: "min significantly underrented", icon: <BsArrowDownCircle size={32} />, color: '#FF0000' },
  '-50%': { text: "underrented", icon: <BsArrowDownCircle size={32} />, color: '#ED7D31' },
  '-25%': { text: "slightly underrented", icon: <BsArrowDownRightCircle size={32} />, color: '#FFC000' },
  '-5%': { text: "rackrented", icon: <BsArrowRightCircle size={32} />, color: '#7F7F7F' },
  '5%': { text: "slightly overrented", icon: <BsArrowUpRightCircle size={32} />, color: '#B4DE97' },
  '25%': { text: "overrented", icon: <BsArrowUpCircle size={32} />, color: '#92D050' },
  '50%': { text: "max significantly overrented", icon: <BsArrowUpCircle size={32} />, color: '#00B050' },

}

function Dashboard({ AssetSelect, assetData }) {
  const dispatch = useDispatch();
  const param = useParams();
  const [showImage, setShowImage] = useState(false);
  const [active, setactive] = useState("image");
  const [showMap, setShowMap] = useState(false);

  const { termialBaseRentType, termialValueType } = useSelector(
    (state) => state.lookUps
  );
  const { AssetTerminalValueCalulationData } = useSelector(
    (state) => state.corporateDashboardData
  );
  const [baseRentValue, setBaseRentValue] = useState(null);
  const [typeValue, setTypeValue] = useState(null);

  const { assetTenantsDetails, assetAreaAndIncomeDetails, assetFinanceDetails, assetCashflows, assetKPIs, assetHVLParams } = useSelector((state) => state.calculation);
  // console.log(assetKPIs, 'assetKPIs')
  const { occupiedArea } = assetHVLParams

  useEffect(() => {
    const finalData = {
      AssetId: param.assetDetailId,
    };
    dispatch(AssetTerminalValueCalulation(finalData));
  }, []);

  useEffect(() => {
    const finalData = {
      type: "termialBaseRentType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  useEffect(() => {
    const finalData = {
      type: "termialValueType",
    };
    dispatch(getByTypeDropdown(finalData));
  }, [dispatch]);

  const initialCarouselData = [
    {
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/GoldenGateBridge-001.jpg/1200px-GoldenGateBridge-001.jpg",
    },
    {
      image:
        "https://cdn.britannica.com/s:800x450,c:crop/35/204435-138-2F2B745A/Time-lapse-hyper-lapse-Isle-Skye-Scotland.jpg",
    },
    {
      image:
        "https://static2.tripoto.com/media/filter/tst/img/735873/TripDocument/1537686560_1537686557954.jpg",
    },
    {
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Palace_of_Fine_Arts_%2816794p%29.jpg/1200px-Palace_of_Fine_Arts_%2816794p%29.jpg",
    },
    {
      image:
        "https://i.natgeofe.com/n/f7732389-a045-402c-bf39-cb4eda39e786/scotland_travel_4x3.jpg",
    },
    {
      image:
        "https://www.tusktravel.com/blog/wp-content/uploads/2020/07/Best-Time-to-Visit-Darjeeling-for-Honeymoon.jpg",
    },
    {
      image:
        "https://images.ctfassets.net/bth3mlrehms2/6Ypj2Qd3m3jQk6ygmpsNAM/61d2f8cb9f939beed918971b9bc59bcd/Scotland.jpg?w=750&h=422&fl=progressive&q=50&fm=jpg",
    },
    {
      image:
        "https://www.oyorooms.com/travel-guide/wp-content/uploads/2019/02/summer-7.jpg",
    },
  ];

  const [carouselData, setCarouselData] = useState(initialCarouselData);

  const onImageChange = (event) => {
    setCarouselData((current) => [
      ...current,
      { image: URL.createObjectURL(event.target.files[0]) },
    ]);
  };

  // console.log(assetData, 'assetData')

  const handleMapClick = () => {
    setShowMap(true);
    setactive("loc");
  };

  const handleCarouselClick = () => { };

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }

  const moveToNext = () => {
    const finalData = {
      AssetId: param.assetDetailId,
    };
    dispatch(AssetTerminalValueCalulation(finalData));
  };

  const handleFinancingValue = () => {
    const finalData = {
      terminalValueId: AssetTerminalValueCalulationData?.terminalValueId
        ? AssetTerminalValueCalulationData?.terminalValueId
        : 0,
      assetId: parseFloat(param.assetDetailId),
      baseRentId: baseRentValue
        ? baseRentValue
        : AssetTerminalValueCalulationData?.baseRentId,

      typeId: typeValue ? typeValue : AssetTerminalValueCalulationData?.typeId,
    };
    dispatch(
      SaveAssetTerminalValueCalulation({ finalData, moveToNext, notifyToaster })
    );
  };

  // const para = document.querySelector('p');
  // const compStyles = window.getComputedStyle(para);
  // para.textContent = `My computed font-size is ${compStyles.getPropertyValue('font-size')},\n` +
  //   `and my computed line-height is ${compStyles.getPropertyValue('line-height')}.`;

  // console.log(para)

  const occupancyRateChart = {
    series: [getFormattedEnglishNumber(assetKPIs?.occupancy * 100)],
    options: {
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 0,
            size: '67%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
          },
          track: {
            background: "#1D4ED8",
            strokeWidth: '90%',
            margin: 5, // margin is in pixels

            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      grid: {
        padding: {
          top: -10
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'horizontal',
          shadeIntensity: 1,
          gradientToColors: ['#bae6fd'], // Array of colors
          inverseColors: true,
          opacityFrom: 76,
          opacityTo: 76,
          stops: [0, 100]
        }
      },
      labels: ['Average Results'],
    },
  }
  // console.log(assetAreaAndIncomeDetails, 'assetAreaAndIncomeDetails')
  // console.log(assetCashflows, 'assetCashflows')
  console.log(assetTenantsDetails, 'assetTenantsDetails')
  console.log(assetFinanceDetails, 'assetFinanceDetails')
  return (
    <div className="asset-dashboard-wrapper">
      {/* <h2>Area and Income Details</h2>
      <JsonView data={assetAreaAndIncomeDetails} shouldExpandNode={(e) => {
        if (e === 0)
          return true
        if (e === 1)
          return true
        return false
      }}
        style={defaultStyles} />
      <h2>Asset Cashflows</h2>
      <JsonView data={assetCashflows} shouldExpandNode={(e) => {
        if (e === 0)
          return true
        if (e === 1)
          return true
        return false
      }}
        style={defaultStyles} /> */}
      <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs" style={{ paddingInline: '12px', }}>
        <div className="d-flex justify-content-end align-items-center" style={{ background: '#fff', padding: '10px 15px', border: '1.5px solid #B0BACD', borderRadius: '8px', marginInline: '10px' }}>
          {AssetSelect && <AssetSelect />}
        </div>
      </Sticky>
      <div style={{ paddingTop: '20px' }}>
        <Row className="office-retail-wrapper" gutter={[20, 20]}>
          <Col xs={24} xl={13} xxl={12}>
            <div className="parent">
              <div className="icon d-flex justify-content-between w-100 p-3">
                <span className="p-2 rounded-sm d-flex align-items-center" style={{ gap: '8px' }}>
                  {/* <div className="upload_btn">
                    <label htmlFor="upload" className="d-flex">
                      <IoMdImages
                          size={27}
                          className="text-blue ml-1 mr-2 my-0 cursor-pointer"
                          style={{
                            color: `${active === "image" ? "#00215B" : "#DADADA"}`,
                          }}
                          onClick={() => setactive("image")}
                      />
                      <input
                          id="upload"
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={onImageChange}
                      />
                    </label>
                  </div> */}
                  <div className="text-white cursor-pointer"
                    onClick={() => handleMapClick()} style={{ background: '#1F1F1FB3', padding: '6px', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <HiOutlineLocationMarker
                      size={20}
                      className="text-white"
                    />
                  </div>
                  <div onClick={() => setShowImage(true)}
                    className="cursor-pointer"
                    style={{ background: '#1F1F1FB3', padding: '6px', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FaExpandAlt
                      size={20}
                      className="text-white"
                    />
                  </div>
                </span>
              </div>
              <Carousel
                // slideNumber
                onClick={() => handleCarouselClick()}
                className="slider"
                data={carouselData}
                height="450px"
                width="100%"
                pauseIconColor="white"
                pauseIconSize="40px"
                slideBackgroundColor="white"
                slideImageFit="cover"
                thumbnails
              />
            </div>
          </Col>
          <Col xs={24} xl={11} xxl={12}>
            <div className="custom-card">
              <h2 style={{ fontWeight: 600 }} className="mb-0">Office & Retail Building</h2>
              <p className="m-0 d-flex align-items-center text-subtitle " style={{ fontSize: '18px' }}>
                {/* Große Gallusstraße 32 */}
                {assetData?.address?.street} {getFormattedEnglishNumber(assetData?.address?.streetNo)}
              </p>
              <p className="m-0 d-flex align-items-center text-subtitle " style={{ fontSize: '18px' }}>
                {/* 60231 Frankfurt am Main | Germany */}
                {assetData?.address?.zipCode} {assetData?.address?.city} {assetData?.address?.federalState?.translations?.en} | {assetData?.address?.country?.translations?.en}
              </p>
            </div>
            <Row gutter={[20, 20]} style={{ paddingTop: '20px' }}>
              <Col xs={24} md={12} xxl={8}>
                <div className="custom-card">
                  <h2 className="mb-0" style={{ fontWeight: 600, }}>Occupancy Rate</h2>
                  <div className="d-flex align-items-end" style={{ gap: "5px" }}>
                    <p className="mb-0" style={{ color: '#6F8BEC' }}>0</p>
                    <Chart options={occupancyRateChart.options} series={occupancyRateChart.series} type="radialBar" height={200} width={190} />
                    <p className="mb-0" style={{ color: '#6F8BEC' }}>100</p>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} xxl={8}>
                <div className="custom-card">
                  <div className="d-flex justify-content-between align-items-center " style={{ gap: '5px', flexWrap: 'wrap' }}>
                    <h2 className="mb-0" style={{ fontWeight: 600, }}>WALT</h2>
                    <div className="d-flex justify-content-between align-items-center" style={{ gap: '5px', flexWrap: 'wrap' }}>
                      <span style={{ fontSize: '12px', color: '#797979' }}>@Analysis Date:</span>
                      <span style={{ fontSize: '12px', color: '#011E55' }}>{dayjs(assetData?.analysisDate).format("DD.MM.YYYY")}</span>
                    </div>
                  </div>
                  <div style={{ paddingTop: '1.25rem' }}>
                    <p className="mb-0" style={{ color: '#0072C3', fontWeight: 600 }}>{assetKPIs?.walt?.toFixed(1)} Years</p>
                    <div className="progress-bar-container">
                      <Progress
                        percent={assetKPIs?.walt * 10}
                        showInfo={false}
                        strokeColor="#0057e7"
                        trailColor="#e0e0e0"
                        strokeWidth={12}
                        style={{ borderRadius: '8px' }}
                      />
                      <div className="dot-container">
                        <div className="dot" style={{ left: '48%' }}></div>
                        <div className="dot" style={{ left: '95%' }}></div>
                      </div>
                    </div>
                    <div>
                      <div className="d-flex justify-content-between align-items-center"
                        style={{ borderTop: '1px solid #B0BACD', borderRight: '1px solid #B0BACD', borderLeft: '1px solid #B0BACD', borderRadius: '10px', height: '10px' }}
                      >
                        <div style={{ height: '10px' }} />
                        <div style={{ borderLeft: '1px solid #B0BACD', height: '10px' }} />
                        <div style={{ borderLeft: '1px solid #B0BACD', height: '10px' }} />
                        <div style={{ borderLeft: '1px solid #B0BACD', height: '10px' }} />
                        < div style={{ height: '10px' }} />
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0">0</p>
                        <p className="mb-0"></p>
                        <p className="mb-0 pl-3">5</p>
                        <p className="mb-0"></p>
                        <p className="mb-0">{`>10`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} xxl={8}>
                <div className="custom-card">
                  <h2 className="mb-0" style={{ fontWeight: 600, }}>Rental Analysis</h2>
                  <div className="text-center">
                    <span style={{ fontSize: '12px', color: '#797979' }}>{rentalAnalysisIcons["-100%"]?.text}</span>
                  </div>
                  <div className="d-flex justify-content-center" >
                    <div className="d-flex justify-content-between align-items-center" style={{ gap: '24px' }}>
                      <div className="text-center">
                        <p className="mb-0" style={{ fontSize: '15px', fontWeight: 600, color: '#01215D' }}>Passing</p>
                        <p className="mb-0" style={{ fontSize: '15px', color: '#01215D' }}>{getFormattedDecimalNumber(assetAreaAndIncomeDetails?.totalValues?.netOperatingIncomeSqm)}</p>
                        <p className="mb-0" style={{ fontSize: '15px', color: '#797979' }}>€/sqm</p>
                      </div>
                      <div style={{ color: rentalAnalysisIcons["-100%"]?.color }}>
                        {rentalAnalysisIcons["-100%"]?.icon}
                      </div>
                      <div className="text-center">
                        <p className="mb-0" style={{ fontSize: '15px', fontWeight: 600, color: '#01215D' }}>Market</p>
                        <p className="mb-0" style={{ fontSize: '15px', color: '#01215D' }}>{getFormattedDecimalNumber(assetAreaAndIncomeDetails?.totalValues?.marketRentalIncomeSqm)}</p>
                        <p className="mb-0" style={{ fontSize: '15px', color: '#797979' }}>€/sqm</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={[20, 20]} style={{ paddingTop: '20px' }}>
              <Col xs={24} md={12} xl={8}>
                <div className="custom-card">
                  <h2 className="mb-0" style={{ fontWeight: 500, }}>Lettable Area</h2>
                  <div className="d-flex justify-content-between align-items-center" style={{ gap: '5px', flexWrap: 'wrap', paddingTop: "12px" }}>
                    <div>
                      <p className="mb-0" style={{ fontSize: '16px', color: '#000' }}>Total</p>
                      <p className="mb-0" style={{ fontSize: '16px', fontWeight: 600, color: '#344D7D' }}>{getFormattedEnglishNumber(assetAreaAndIncomeDetails?.totalValues?.totalAreaUnits ?? 0)} sqm</p>
                    </div>
                    <div>
                      <p className="mb-0" style={{ fontSize: '16px', color: '#000' }}>Unit</p>
                      <p className="mb-0" style={{ fontSize: '16px', fontWeight: 600, color: '#344D7D' }}>{getFormattedEnglishNumber(assetAreaAndIncomeDetails?.totalRentalTypeValues?.unit?.totalAreaUnits)}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center" style={{ gap: '5px', flexWrap: 'wrap', paddingTop: "14px", }}>
                    <div>
                      <p className="mb-0" style={{ fontSize: '16px', color: '#000' }}>Vacant</p>
                      <p className="mb-0" style={{ fontSize: '16px', fontWeight: 600, color: '#344D7D' }}>{getFormattedEnglishNumber(assetAreaAndIncomeDetails?.totalValues?.vacant ?? 0)} sqm</p>
                    </div>
                    <div>
                      <p className="mb-0" style={{ fontSize: '16px', color: '#000' }}>Structural</p>
                      <p className="mb-0" style={{ fontSize: '16px', fontWeight: 600, color: '#344D7D' }}>{getFormattedEnglishNumber(0)} sqm</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <div className="custom-card">
                  <h2 className="mb-0" style={{ fontWeight: 500, }}>Tenancy</h2>
                  <div style={{ paddingTop: '13px' }}>
                    <p className="mb-0" style={{ fontSize: '16px', color: '#000' }}>Main Tenance</p>
                    <p className="mb-0" style={{ fontSize: '15px', fontWeight: 600, color: '#344D7D' }}>{assetTenantsDetails?.topTenantName}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center" style={{ gap: '5px', flexWrap: 'wrap', paddingTop: "13px", }}>
                    <div>
                      <p className="mb-0" style={{ fontSize: '16px', color: '#000' }}>Total Tenant</p>
                      <p className="mb-0" style={{ fontSize: '16px', fontWeight: 600, color: '#344D7D' }}>{getFormattedEnglishNumber(assetTenantsDetails?.totalTenants)}</p>
                    </div>
                    <div>
                      <p className="mb-0" style={{ fontSize: '16px', color: '#000' }}>Icome Top 3</p>
                      <p className="mb-0" style={{ fontSize: '16px', fontWeight: 600, color: '#344D7D' }}>{getFormattedEnglishNumber(assetTenantsDetails?.shareIncomeTop3)}</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <div className="custom-card">
                  <div className="d-flex justify-content-between align-items-center" style={{ flexWrap: 'wrap', gap: '5px' }}>
                    <div>
                      <h2 className="mb-0" style={{ fontWeight: 500, }}>Site</h2>
                      <div style={{ paddingTop: '10px' }}>
                        <p className="mb-0" style={{ fontSize: '16px', color: '#000' }}>Area</p>
                        <p className="mb-0" style={{ fontSize: '16px', fontWeight: 600, color: '#344D7D' }}>{assetData?.assetDetails?.totalSiteArea ? `${getFormattedEnglishNumber(assetData?.assetDetails?.totalSiteArea)} sqm` : "NA"}</p>
                      </div>
                      <div style={{ paddingTop: '15px' }}>
                        <p className="mb-0" style={{ fontSize: '16px', color: '#000' }}>Ownership</p>
                        <p className="mb-0" style={{ fontSize: '16px', fontWeight: 600, color: '#344D7D' }}>{assetData?.assetDetails?.ownershipType ?? "NA"}</p>
                      </div>
                    </div>
                    <div>
                      <img src={IMAGES.SITE_MAP} alt="ownership_map" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} xl={13} xxl={12}>
            <AccquisitionCostsSummary assetKPIs={assetKPIs} assetFinanceDetails={assetFinanceDetails} />
          </Col>
          <Col xs={24} xl={11} xxl={12}>
            <AccquisitionCostsExitSummary assetKPIs={assetKPIs} />
          </Col>
          <Col xs={24} xl={12}>
            <TenantAnalysis assetTenantsDetails={assetTenantsDetails} />
          </Col>
          {assetFinanceDetails !== null &&
            <Col xs={24} xl={12}>
              <LoanCost assetFinanceDetails={assetFinanceDetails} />
            </Col>
          }
          <Col xs={24}>
            <AssetDetailsTables assetAreaAndIncomeDetails={assetAreaAndIncomeDetails} />
          </Col>
          <Col xs={24}>
            <CashFlowCharts assetAreaAndIncomeDetails={assetAreaAndIncomeDetails} assetCashflows={assetCashflows} />
          </Col>
        </Row>

        {/* <Row gutter={16} className="mt-3">
              <Col xs={24} md={12}>
                <ValidationResults />
              </Col>
              <Col xs={24} md={12}>
                <SensitiveAnalysisCard />
              </Col>
              <Col xs={24}>
                <LeaseAnalysisChart />
              </Col>
              <Col xs={24}>
                <AreaIncomeAnalysis />
              </Col>
              <Col xs={24}>
                <TanentsAnalysis />
              </Col>
              <Col xs={24} md={12}>
                <CashFlowChart />
              </Col>
              <Col xs={24} md={12}>
                <FinancialKeyFigures />
              </Col>
            </Row> */}
      </div>
    </div>
  );
}

export default Dashboard;
