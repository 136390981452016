import {Button, Col, DatePicker, Form, Input, Row, Select, Spin} from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getByTypeDropdown } from "../../../../../../../app/features/Lookups/lookup.slice";
import {I18n} from "reia-rest-client";
import {getIndexValue} from "../../../../../../../app/features/calculation/calculationAsset.slice";

function Indexation(props) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { next, prev, handleCancel } = props;
  const dispatch = useDispatch();
  const { indexationTypes, cpiTypes } = useSelector((state) => state.lookUps);
  const [indexValueIsLoading, setIndexValueIsLoading] = useState(false)
  const [nextIndexValueIsLoading, setNexIndexValueIsLoading] = useState(false)

  const onFinish = (values) => {
    // console.log("Success:", values);
    localStorage.setItem("indexation", JSON.stringify(values));

    next();
  };
  const localStorageData = localStorage.getItem("indexation");
  const FormData = localStorageData ? JSON.parse(localStorageData) : null;
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "indexationTypes",
    }
    dispatch(
      getByTypeDropdown({finalData})
    );
  }, []);

  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "cpiTypes",
    }
    dispatch(
        getByTypeDropdown({finalData})
    );
  }, []);

  const onChange = (date, dateString) => {
    //do some stuff
  };

  const updateIndexValue = (paramName) => {
    form?.setFieldValue(paramName, null);
  }

  const updateIndexValueByCpiValue = (indexDate, paramName) => {
    
    const cpiType = form?.getFieldValue("index");
    if(cpiType)
    {
      const cpiI18nItem = cpiTypes.filter((i18nItem: I18n) => i18nItem.id === cpiType)[0]
      
      if (form && indexDate && cpiI18nItem) {
        const indexType = cpiI18nItem?.key === "core-data.cpiTypes.vpi-2020" ? "cpi2020" : cpiI18nItem?.key === "core-data.cpiTypes.vpi-2015" ? "cpi2015" : cpiI18nItem?.key
        const date = moment.utc(indexDate);

        switch (paramName){
          case "lastIndexValue":
            setIndexValueIsLoading(true)
            break;
          case "nextIndexValue":
            setNexIndexValueIsLoading(true)
            break;
        }
        console.log(paramName)
        dispatch(getIndexValue({finalData : {
            indexType: indexType,
            indexDate: date.year() + "-" + (date.month() + 1),
            additionalParams: {}}
        })).then((response) => {
          console.log(response.payload);
          if(response.payload && response.payload.indexValue)
          {
            form?.setFieldValue(paramName, response.payload.indexValue);
          }else{
            form?.setFieldValue(paramName, null);
          }
          switch (paramName){
            case "lastIndexValue":
              setIndexValueIsLoading(false)
              break;
            case "nextIndexValue":
              setNexIndexValueIsLoading(false)
              break;
          }
        });
      }
    }
    
  }

  const updateCpiType =  (value, field, record) => {
    const indexDate = form?.getFieldValue("lastDate");
    updateIndexValueByCpiValue(indexDate, "lastIndexValue")
    
    const nextIndexDate = form?.getFieldValue("nextDate");
    updateIndexValueByCpiValue(nextIndexDate, "nextIndexValue")

  };

  const dateChange =  (value, field) => {
    if(field === "lastDate")
      updateIndexValueByCpiValue(value?._d, "lastIndexValue")

    if(field === "nextDate")
      updateIndexValueByCpiValue(value?._d, "nextIndexValue")
  };
  
  
  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">Indexation</h3>
      <Form 
        form={form}
        name="basic"
        className="user-modal flex-column justify-content-between d-flex"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Type"
              name="type"
              initialValue={FormData?.type}
              rules={[
                {
                  required: true,
                  message: "Please input type",
                },
              ]}
            >
              <Select placeholder="Selection" allowClear>
                {indexationTypes?.map((item:I18n) => (
                  <Option key={item.id} value={item.id}>
                    {item.translations.en}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Index"
              name="index"
              initialValue={FormData?.index}
              rules={[
                {
                  required: true,
                  message: "Please input index",
                },
              ]}
            >
              <Select placeholder="Selection" allowClear onChange={updateCpiType}>
                {cpiTypes?.map((item:I18n) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.translations.en}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
                label="Last Index Date"
                name="lastDate"
                format="DD.MM.YYYY"
                initialValue={
                  FormData?.lastDate ? moment(FormData?.lastDate) : ""
                }
            >
              <DatePicker
                  placeholder="DD.MM.YYYY"
                  className="w-100"
                  onChange={(value) => {dateChange(value, "lastDate")}}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
                label="Last Index Value"
                name="lastIndexValue"
                initialValue={FormData?.lastIndexValue}
                rules={[
                  {
                    required: false,
                    message: "Please input Location!",
                  },
                ]}
            >
              {indexValueIsLoading ? <Spin /> :  <Input placeholder="€" className="w-100" type="number" onChange={() => updateIndexValue("lastDate")}/>}
            </Form.Item>
          </Col>
          {/*<Col xs={24} md={12} lg={8}>*/}
          {/*  <Form.Item*/}
          {/*    label="First"*/}
          {/*    name="firstDate"*/}
          {/*    format="MM/DD/YYYY"*/}
          {/*    initialValue={*/}
          {/*      FormData?.firstDate ? moment(FormData?.firstDate) : ""*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <DatePicker*/}
          {/*      placeholder="DD.MM.YYYY"*/}
          {/*      className="w-100"*/}
          {/*      onChange={onChange}*/}
          {/*    />*/}
          {/*  </Form.Item>*/}
          {/*</Col>*/}
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Next Adjustment"
              name="nextDate"
              format="DD.MM.YYYY"
              initialValue={
                FormData?.nextDate ? moment(FormData?.nextDate) : ""
              }
            >
              <DatePicker placeholder="DD.MM.YYYY" 
                          className="w-100"
                          onChange={(value) => {dateChange(value, "nextDate")}}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
                label="Next Index Value"
                name="nextIndexValue"
                initialValue={FormData?.nextIndexValue}
                rules={[
                  {
                    required: false,
                    message: "Please input Location!",
                  }
                ]}
            >
              {nextIndexValueIsLoading ? <Spin /> : <Input placeholder="€" className="w-100" type="number" onChange={() => updateIndexValue("nextDate")}/>}
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Cycle"
              name="cycle"
              initialValue={FormData?.cycle}
              rules={[
                {
                  required: false,
                  message: "Please input Location!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="Months" className="w-100" type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Hurdle %/pts"
              name="hurdle"
              initialValue={FormData?.hurdle}
              rules={[
                {
                  required: false,
                  message: "Please input Location!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="PTS. / %" className="w-100" type="number" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Factor"
              name="factor"
              initialValue={FormData?.factor}
              rules={[
                {
                  required: false,
                  message: "Please input Location!",
                },
                {
                  max: 10,
                  message: "The max lenght of 10 Characters is reached!",
                },
              ]}
            >
              <Input placeholder="%" className="w-100" type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-primary"
                  onClick={prev}
                >
                  Previous
                </Button>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className="btn-primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Indexation;

Indexation.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
  prev: PropTypes.node.isRequired,
};
