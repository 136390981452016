/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { Button, Card, DatePicker, Form, Input, InputNumber, Select, Table, Tooltip } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ImBin2 } from "react-icons/im";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import DeleteUserModal from "../../../../../../../components/Layout/DeleteModel/DeleteUserModal";
import { useDispatch, useSelector } from "react-redux";
import { OtherIncomeCost, OtherIncomeCostTypeEnum } from "reia-rest-client";
import CreateUpdate from "../../../../CreateUpdate";
import Create from "./Create";
import moment from "moment/moment";
import { getFormattedEnglishNumber, getFormattedInt } from "../../../../../../../utils/digitHelper";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Sticky from "react-stickynode";
import IMAGES from "../../../../../../../assets/images";
import { LuRefreshCw } from "react-icons/lu";

export const COST_TYPE = ["OPEX", "CAPEX"]
export const ROTATION = ["ONETIME", "MONTHLY", "QUARTER", "HALFYEARLY", "YEARLY"]

const EditableContext = React.createContext(null);
function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form size="small" form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}
function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  const saveDate = (dataIndex) => {
    return async (date) => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        values[dataIndex] = date.toISOString();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };
  }
  let childNode = children;
  if (editable) {
    childNode = editing ? dataIndex !== "start" && dataIndex !== "end" ?
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        {dataIndex === "value" ? <InputNumber controls={false} ref={inputRef} onPressEnter={save} onBlur={save} /> :
          dataIndex === "description" ? <Input ref={inputRef} onPressEnter={save} onBlur={save} /> :
            dataIndex === "rotation" ? <Select ref={inputRef} onPressEnter={save} onBlur={save} placeholder="Select" allowClear disabled={false} >
              {ROTATION.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select> :
              dataIndex === "type" ? <Select ref={inputRef} onPressEnter={save} onBlur={save} placeholder="Select" allowClear disabled={false} >
                {COST_TYPE.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select> : ""}
      </Form.Item> :
      <DatePicker
        ref={inputRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        onPressEnter={saveDate(dataIndex)} onBlur={saveDate(dataIndex)}
        onChange={saveDate(dataIndex)}
        className="w-100 rounded-sm"
        defaultValue={record?.[dataIndex] && moment(record?.[dataIndex], 'YYYY-MM-DD')}
        // dateFormat="DD.MM.YYYY"
        format="DD.MM.YYYY"
      />
      : (
        <div
          role="presentation"
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
  }
  return <td {...restProps}>{childNode}</td>;
}

export default function App({
  handleToggleDrawer, showDrawer, AssetSelect, saveCalculationDetails, resetCalculationDetail, autoSaveCalc,
  isCalcLoading,
  allDataLoaded,
  calculationModified,
  assetValueMismatched, calculationDetail }) {

  const dispatch = useDispatch();
  const { assetDCFParams, assetDCFResult } = useSelector((state) => state.calculation);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [createCostModal, setCreateCostModal] = useState(false);
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  }
  const closeCreateModal = () => {
    setCreateCostModal(false);
  }

  const setAssetDCFParams = (params) => {
    dispatch({ type: "calculation/setAssetDCFParams", payload: params })
  }

  const handleDelete = () => {
    const otherIncomeCosts = assetDCFParams?.otherIncomeCosts.filter((otherIncomeCost, index) => selectedRowKeys.indexOf(index) === -1);
    setAssetDCFParams({ otherIncomeCosts: otherIncomeCosts })
    setSelectedRowKeys([]);
    closeDeleteUserModal();
  };

  const groundRentColumns = [
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
    },
    {
      title: "Amount (€)",
      dataIndex: "value",
      editable: true,
      render: (text) => getFormattedInt(text) + " €"
    },
    {
      title: "Type",
      dataIndex: "type",
      editable: true,
    },
    {
      title: "Rotation",
      dataIndex: "rotation",
      editable: true,
    },
    {
      title: "Start Date",
      dataIndex: "start",
      editable: true,
      render: (text) => (text && moment(text).format("DD.MM.YYYY")) || "N/A"
    },
    {
      title: "End Date",
      dataIndex: "end",
      editable: true,
      render: (text) => (text && moment(text).format("DD.MM.YYYY")) || "N/A"
    }
  ];
  const handleAdd = () => {
    setCreateCostModal(true);
  };

  const addNewOtherIncomeCost = (data) => {
    data.start = data.start.toISOString()
    if (data.end) data.end = data.end.toISOString()

    const newData = [data, ...assetDCFParams?.otherIncomeCosts];
    setAssetDCFParams({ otherIncomeCosts: newData })
    setCreateCostModal(false);
  }
  const handleSave = (rowIndex) => {

    return (row) => {
      const newData = [...assetDCFParams?.otherIncomeCosts];
      const index = newData.findIndex((item, index) => rowIndex === index);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setAssetDCFParams({ otherIncomeCosts: newData })
    }
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = groundRentColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave(rowIndex),
      }),
    };
  });

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selceted keys", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const newNetAssetValue = assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;
  const disableActions = !autoSaveCalc && allDataLoaded() && !isCalcLoading() && (calculationModified || assetValueMismatched || !calculationDetail?.assetDCFResult?.dcfResult);
  return (
    <div className="incomeAndCost site-card-border-less-wrapper">
      <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs">
        <div style={{
          display: "flex", justifyContent: "space-between", flexWrap: 'wrap',
          alignItems: "center", padding: '10px', background: '#fff',
          border: '1.5px solid #b0bacd', borderRadius: '8px', marginBottom: '20px',
        }}
        >

          <div className="d-flex align-items-center" style={{ gap: '10px' }}>
            <h3 className="mb-0">Other Costs</h3>
            {!showDrawer &&
              <h4 className="mb-0"> Asset Value: {getFormattedEnglishNumber(newNetAssetValue)} €</h4>
            }
          </div>
          <div className="d-flex align-items-center">
            {/* <> */}
            <Tooltip title="Save changes" color="#00215B" key="save-changes">
              <div>
                <Button
                  icon={< IMAGES.SAVE_ICON />}
                  className="save-icon-btn mr-2 cursor-pointer"
                  onClick={saveCalculationDetails}
                  disabled={!disableActions}
                />
              </div>
            </Tooltip>

            <Tooltip title="Reset changes" color="#00215B" key="reset-changes">
              <div>
                <Button
                  icon={<LuRefreshCw size={24} />}
                  className="reset-icon-btn mr-2 cursor-pointer"
                  onClick={resetCalculationDetail}
                  disabled={!disableActions}
                />
              </div>
            </Tooltip>
            {/* </> */}
            <Tooltip title="Delete" color="#00215b" key="delete">
              <div className="ml-2">
                <Button
                  className="reset-icon-btn mr-2 cursor-pointer"
                  onClick={() => setDeleteUserModal(true)}
                  disabled={selectedRowKeys?.length === 0 ? true : false}
                  role="button"
                  tabIndex="0"
                >
                  <AiOutlineDelete size={24} />
                </Button>
              </div>
            </Tooltip>
            <Tooltip title="Add row" color="#00215B" key="add-icon">
              <AiOutlinePlus
                className="add-row-btn p-1 cursor-pointer mx-1"
                onClick={handleAdd}
                size={30}
              />
            </Tooltip>
            {AssetSelect && <AssetSelect />}

            <Tooltip
              title="show asset detail"
              color="#00215B"
              key="#00215B"
            >
              {/* drawer-close-btn */}
              <span
                className={`project-drawer-close-btn cursor-pointer mr-2 ml-2 ${showDrawer ? "d-none" : ""
                  }`}
                role="button"
                tabIndex="0" onClick={handleToggleDrawer}
              >
                <MdOutlineKeyboardArrowLeft
                  size={25}
                  fill="#fff"

                />
              </span>
            </Tooltip>
          </div>
        </div>
      </Sticky >
      <Table
        components={components}
        // bordered
        rowClassName={() => "editable-row"}
        columns={columns}
        dataSource={assetDCFParams?.otherIncomeCosts}
        pagination={false}
        className="antd-custom-card-table antd-table-striped"
        rowKey={(record, rowIndex) => rowIndex}
        rowSelection={rowSelection}
        scroll={{
          y: 410,
        }}
      />
      <DeleteUserModal
        onSubmit={handleDelete}
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        user="Costs"
      />
      <Create
        visible={createCostModal}
        onSubmit={addNewOtherIncomeCost}
        handleCancel={closeCreateModal}
      />
    </div>
  );
}

EditableRow.propTypes = {
  index: PropTypes.node.isRequired,
};

EditableCell.propTypes = {
  title: PropTypes.node.isRequired,
  editable: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  dataIndex: PropTypes.node.isRequired,
  record: PropTypes.node.isRequired,
  handleSave: PropTypes.node.isRequired,
};
