import { Col, Progress, Row, Typography } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useState } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { getRentalPerAnum } from "../../../../../../utils/dcfHelper";
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from "../../../../../../utils/digitHelper";
import { useSelector } from "react-redux";

export default function AssetDetailDrawer(props) {
  const [isActive, setIsActive] = useState("tab1");
  const tabHandleClick = (tab) => {
    setIsActive(tab);
  };
  const { assetTenantsDetails, assetAreaAndIncomeDetails, assetFinanceDetails, assetCashflows, assetKPIs, } = useSelector((state) => state.calculation);
  const {
    setShowDrawer,
    showDrawer,
    assetData,
    projectAssets,
    calculationDetail,
    assetId,
    setAssetId,
  } = props;
  console.log(assetFinanceDetails, 'assetFinanceDetails')
  //*****************Rental per anum
  const currentRental = assetKPIs?.grossRentalIncomeYear

  const marketRental = assetKPIs?.marketRentalIncomeYear

  const potentialRental = assetKPIs?.potentialRentalIncomeYear

  const calculateAdjustedValues = ({ currentRental, potentialRental, marketRental }) => {
    // Step 1: Check if values are above 100
    const values = {
      currentRental: Number(currentRental),
      potentialRental: Number(potentialRental),
      marketRental: Number(marketRental),
    };

    // Step 2: Find the highest value
    const highestValue = Math.max(values.currentRental, values.potentialRental, values.marketRental);

    // Step 3: Calculate percentages for other values
    const adjustedValues = {};
    for (const [key, value] of Object.entries(values)) {
      adjustedValues[key] = value === highestValue || value === 0 ? value : (value / highestValue) * 100;
    }

    return adjustedValues;
  };
  const rents = { currentRental, potentialRental, marketRental };
  const adjustedValues = calculateAdjustedValues(rents);
  console.log(assetKPIs, 'assetKPIs')
  return (
    <div
      className="custom-drawer-content bg-white rounded-sm"
    // style={{ position: "fixed", width: "-webkit-fill-available" }}
    >
      <div
        onClick={() => setShowDrawer(!showDrawer)}
        className="sidebar-collapse-button cursor-pointer"
      >
        <MdOutlineKeyboardArrowRight size={25} fill="#fff" />
      </div>
      <div className="custom-drawer asset-detial-drawer custom-scroll" >
        {/*  p-3 */}
        <div className="drawer_text p-3">
          <h4>{assetData?.assetName}</h4>
          <p className="mb-0">
            {assetData?.assetType?.translations?.en || "N/A"}
          </p>
          <p className="mb-0">
            {`${assetData?.address?.street}, ${assetData?.address?.streetNo}, ${assetData?.address?.city}, ${assetData?.address?.federalState?.translations?.en}, ${assetData?.address?.country?.translations?.en}` ||
              "N/A"}
          </p>
        </div>

        <div className="area-percentage__bar p-3">
          {/* <Row gutter={24} style={{ justifyContent: "space-between" }}>
            <Col xs={4}>
              <p>Area</p>
            </Col>
            <Col xs={16}>
              <p className="text-center">{getFormattedEnglishNumber(assetAreaAndIncomeDetails?.totalValues?.totalAreaUnits)}</p>
            </Col>
            <Col xs={4}>
              <p>sqm</p>
            </Col>
          </Row> */}
          <div className="d-flex justify-content-between align-items-center">
            <p>Area</p>
            <p>{getFormattedEnglishNumber(assetKPIs?.totalArea)} sqm</p>
          </div>
          <Row gutter={24} style={{ justifyContent: "space-between" }}>
            <Col xs={4}>
              <h6>Occup.</h6>
            </Col>

            <Col xs={14}>
              <div className="drawer-rent-progress">
                <Progress
                  percent={getFormattedDecimalNumber(assetKPIs?.occupancy * 100) ?? 0}
                  format={(_) => ""}
                  size="small"
                />
              </div>
            </Col>
            <Col xs={6} className="d-flex justify-content-end">
              <h6>{getFormattedEnglishNumber(assetKPIs?.occupancy * 100)}%</h6>
            </Col>
          </Row>

          <Row gutter={24} style={{ justifyContent: "space-between" }}>
            <Col xs={4}>
              <h6>WALT.</h6>
            </Col>
            <Col xs={14}>
              <div className="drawer-rent-progress">
                <Progress
                  percent={getFormattedEnglishNumber(assetKPIs?.walt) ?? 0}
                  format={(_) => ""}
                  size="small"
                />
              </div>
            </Col>
            <Col xs={6} className="d-flex justify-content-end">
              <h6>{assetKPIs?.walt?.toFixed(1)} years</h6>
            </Col>
          </Row>
        </div>
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
            <h4 >Asset Value</h4>
            <h4 >{getFormattedEnglishNumber(assetKPIs?.netAssetValue)} EUR</h4>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
            <h5 style={{ color: '#1F1F1F', }}>per sq m</h5>
            <h5 style={{ color: '#1F1F1F', }}> {getFormattedDecimalNumber(assetKPIs?.netAssetValueSqm)} EUR</h5>
          </div>
        </div>
        <div className="drawer-tabs px-3 pb-3" style={{ borderBottom: '1px solid #E6E9EF' }}>
          <div className="drawer-tab__list">
            <button
              onClick={() => {
                tabHandleClick("tab1");
              }}
              className={isActive === "tab1" ? "active" : ""}
            >
              Analysis Date
            </button>
            <button
              onClick={() => {
                tabHandleClick("tab2");
              }}
              className={isActive === "tab2" ? "active" : ""}
            >
              Exit
            </button>
          </div>
          <div className="drawer-tab__content px-1">
            {isActive === "tab1" && (
              <>
                {/* <h5 className="mb-0 text-black pt-2">Yiel Profile</h5> */}
                <div
                  className="pt-2"
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "end",
                    justifyContent: "space-between",
                    paddingRight: "5px",
                  }}
                >
                  <div>
                    <h4 className="mb-0 text-black">Yield Profile</h4>
                    <h5 className="mb-0 text-black font-weight-normal">@Current</h5>
                    <h5 className="mb-0 text-black font-weight-normal">@Potential</h5>
                    <h5 className="mb-0 text-black font-weight-normal">@Market</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">NIY</h4>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.netInitialYieldCurrentRent * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.netInitialYieldPotentialRent * 100)}%
                    </Typography>
                    <Typography>{getFormattedDecimalNumber(assetKPIs?.netInitialYieldMarketRent * 100)}%</Typography>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">GIY</h4>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.grossInitialYieldCurrentRent * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.grossInitialYieldPotentialRent * 100)}%
                    </Typography>
                    <Typography>{getFormattedDecimalNumber(assetKPIs?.grossInitialYieldMarketRent * 100)}%</Typography>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">M</h4>
                    <Typography>{getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldCurrentRent)}</Typography>
                    <Typography>{getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldPotentialRent)}</Typography>
                    <Typography>{getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldMarketRent)}</Typography>
                  </div>
                </div>
              </>
            )}
            {isActive === "tab2" && (
              <>
                {/* <h4 className="mb-0 text-black pt-2">Yiel Profile @ Sale</h4> */}
                <div
                  className="pt-2"
                  style={{
                    display: "flex",
                    gap: "9px",
                    alignItems: "end",
                    justifyContent: "space-between",
                    paddingRight: "5px",
                  }}
                >
                  <div>
                    <h4 className="mb-0 text-black">Yield Profile @ Sale</h4>
                    <h5 className="mb-0 text-black font-weight-normal">@Current</h5>
                    <h5 className="mb-0 text-black font-weight-normal">@Potential</h5>
                    <h5 className="mb-0 text-black font-weight-normal">@Market</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">NIY</h4>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.netInitialYieldTerminalCurrentRent * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.netInitialYieldTerminalPotentialRent * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.netInitialYieldTerminalMarketRent * 100)}%
                    </Typography>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">GIY</h4>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.grossInitialYieldTerminalCurrentRent * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.grossInitialYieldTerminalPotentialRent * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.grossInitialYieldTerminalMarketRent * 100)}%
                    </Typography>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">M</h4>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldTerminalCurrentRent)}
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldTerminalPotentialRent)}
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(assetKPIs?.multipleInitialYieldTerminalMarketRent)}
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <div>
            <h5 className="mb-0 text-black">Asset ID</h5>
            <p className="mb-0 text-gray ">{assetData?.id || "N/A"}</p>
          </div>
          <div className="pt-2">
            <h5 className="mb-0 text-black">Asset Type</h5>
            
          </div>
          <div className="pt-2">
            <h5 className="mb-0 text-black">Address</h5>
            
          </div>
          <hr className="my-2" /> */}
        {/* <div>
            <h5 className="mb-0 text-black">Analysis Date</h5>
            <p className="mb-0 text-gray ">
              {dayjs(assetData?.analysisDate).format("DD.MM.YYYY") || "N/A"}
            </p>
          </div> */}
        {/* <div className="pt-2">
            <h5 className="mb-0 text-black">Vacancy Rate</h5>
            <p className="mb-0 text-gray ">
              {`${(vacancyRate * 100).toFixed()}%` || "N/A"}
            </p>
          </div> */}
        {/* <div className="pt-2">
            <h5 className="mb-0 text-black">WALT</h5>
            <p className="mb-0 text-gray ">
              {`${waltYears.toFixed()}Years` || "N/A"}
            </p>
          </div>
          <div className="pt-2">
            <h5 className="mb-0 text-black">Total Lettable Area</h5>
            <p className="mb-0 text-gray ">
              {`${totalLettableArea} sqm` || "N/A"}
            </p>
          </div> */}

        <div className="p-3" style={{ borderBottom: "1px solid #E6E9EF" }}>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
            <h4 className="mb-0">Rental Level:</h4>
            <h4 className="mb-0">{getFormattedDecimalNumber(assetKPIs?.rentalLevel * 100)}%</h4>
          </div>
          <h5 className="font-weight-normal">Undemented</h5>
          <div className="pt-2">
            <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
              <h5 className=" text-black font-weight-normal">Current Rent p.a.</h5>
              <h5 className=" text-black font-weight-normal">{getFormattedEnglishNumber(currentRental)} EUR</h5>
            </div>
            <Row gutter={24} style={{ justifyContent: "space-between" }}>
              <Col xs={16}>
                <div className="drawer-rent-progress">
                  <Progress
                    percent={adjustedValues?.currentRental ?? 0}
                    format={(_) => ""}
                    style={{ "& .ant-progress-outer": { padding: 0 } }}
                    size="small"
                  />
                </div>
              </Col>
              <Col xs={8} className="d-flex justify-content-end">
                <h6 className="font-size-sm" style={{ color: '#9B9B9B' }}>
                  {getFormattedEnglishNumber(assetKPIs?.grossRentalIncomeSqm)} EUR/sqm</h6>
              </Col>
            </Row>
          </div>
          <div className="pt-2">
            <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
              <h5 className=" text-black font-weight-normal">Market Rent p.a.</h5>
              <h5 className=" text-black font-weight-normal">{getFormattedEnglishNumber(marketRental)} EUR</h5>
            </div>
            <Row gutter={24} style={{ justifyContent: "space-between" }}>
              <Col xs={16}>
                <div className="drawer-rent-progress">
                  <Progress
                    percent={adjustedValues?.marketRental ?? 0}
                    format={(_) => ""}
                    size="small"
                  />
                </div>
              </Col>
              <Col xs={8} className="d-flex justify-content-end">
                <h6 className="font-size-sm" style={{ color: '#9B9B9B' }}>
                  {getFormattedEnglishNumber(assetKPIs?.marketRentalIncomeSqm)} EUR/sqm</h6>
              </Col>
            </Row>
          </div>
          <div className="pt-2">
            {/* <h5 className="mb-0 text-black font-weight-normal">Potential Rent p.a.</h5>
              <p className="mb-0 text-gray ">
                {`${potentialRental} EUR` || "N/A"}
              </p> */}
            <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
              <h5 className=" text-black font-weight-normal">Potential Rent p.a.</h5>
              <h5 className=" text-black font-weight-normal">{getFormattedEnglishNumber(potentialRental)} EUR</h5>
            </div>
            <Row gutter={24} style={{ justifyContent: "space-between" }}>
              <Col xs={16}>
                <div className="drawer-rent-progress">
                  <Progress
                    percent={adjustedValues?.potentialRental ?? 0}
                    format={(_) => ""}
                    size="small"
                  />
                </div>
              </Col>
              <Col xs={8} className="d-flex justify-content-end">
                <h6 className="font-size-sm" style={{ color: '#9B9B9B' }}>
                  {getFormattedEnglishNumber(assetKPIs?.potentialRentalIncomeSqm)} EUR/sqm</h6>
              </Col>
            </Row>
          </div>
        </div>
        {/* <hr className="my-2" />*/}
        <div className="p-3" style={{ borderBottom: "1px solid #E6E9EF" }}>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">Leveraged IRR</h5>
            <h5 className="mb-0 text-black font-weight-normal">{`${getFormattedDecimalNumber(assetKPIs?.financingLeveragedIRR ? assetKPIs?.financingLeveragedIRR * 100 : assetKPIs?.financingLeveragedIRR)}  %` || "N/A"}</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap pt-2" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">Cash on Cash</h5>
            <h5 className="mb-0 text-black font-weight-normal">{`${getFormattedDecimalNumber(assetKPIs?.financingCashOnCash ? assetKPIs?.financingCashOnCash * 100 : assetKPIs?.financingCashOnCash)}  %` || "N/A"}</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap pt-2" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">WACC</h5>
            <h5 className="mb-0 text-black font-weight-normal">{`${getFormattedDecimalNumber(assetKPIs?.financingWacc ? assetKPIs?.financingWacc * 100 : assetKPIs?.financingWacc)}  %` || "N/A"}</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap pt-2" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">Equity Multiple</h5>
            <h5 className="mb-0 text-black font-weight-normal">{`${getFormattedDecimalNumber(assetKPIs?.financingEquityMultiple ? assetKPIs?.financingEquityMultiple * 100 : assetKPIs?.financingEquityMultiple)}  %` || "N/A"}</h5>
          </div>
        </div>
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">Analysis Date:</h5>
            <h5 className="mb-0 text-black font-weight-normal">{dayjs(calculationDetail?.analysisDate).format("DD.MM.YYYY") || "N/A"}</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap pt-2" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">Last Change</h5>
            <h5 className="mb-0 text-black font-weight-normal">{"01.04.2024" || "N/A"}</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap pt-2" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">User</h5>
            <h5 className="mb-0 text-black font-weight-normal">{"JB" || "N/A"}</h5>
          </div>
        </div>
      </div>
    </div >
  );
}

AssetDetailDrawer.propTypes = {
  setShowDrawer: PropTypes.node.isRequired,
  showDrawer: PropTypes.node.isRequired,
};
