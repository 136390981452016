import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import calculation from "./calculationAsset.service";
import {compareAssetValue, deepObjectAsStringCompare} from "../../../utils/dcfHelper";
import {
    calculateAssetKpis,
    getAreaAndIncomeDetails,
    getFinanceDetails,
    getTenantDetails
} from "../../../utils/kpiHelper";
import {accumulateAssetCashflows} from "../../../utils/cashflowHelper";
import {AssetDto, DCFParameters} from "reia-rest-client";
import {DCFOutput} from "reia-dcf-client";

const initialState = {

    calculationDetail: null,
    isCalculationDetailLoading: false,
    assetDCFParams: null,
    assetDCFResult: {
        dcfResult: null,
    },
    rentRollAssumptions: {},
    assetHVLParams: {
        debugCalc: false
    },
    calculationModified: false,
    assetValueMismatched: false,
    isError: false,
    isSuccess: false,
    isAssetDCFCalcLoading: false,
    statusMessage: "",
    autoSaveCalc: false,
    resetData: false,
    assetAreaAndIncomeDetails: null,
    assetTenantsDetails: null,
    assetFinanceDetails: null,
    assetCashflows: null,
    assetKPIs: {},
    kpisModified: false,
    assumptionsModified: false
};


export const getAssetDCFResult = createAsyncThunk(
    "calculation/getAssetDCFResult",
    async ({ finalData, notifyToaster }, thunkAPI) => {
        try {
            // console.log("DCF Inputs: ", finalData)
            const response = await calculation.getAssetDCFResult(finalData);
            // console.log("DCF Response: ", response)
            return { dcfResult: response };
        } catch (error) {
            console.log(error)
            if (error.response.data) {
                if (error.response.data.title) {
                    notifyToaster("DCF calculation: " + error.response.data.title + ". Errors: " + JSON.stringify(error.response.data.errors), "error");
                } else {
                    notifyToaster("DCF calculation: " + error.response.data, "error");
                }
            }
            else {
                notifyToaster(error.message, "error");
            }
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getCalculationDetail = createAsyncThunk(
    "calculation/getCalculationDetail",
    async ({ finalData, notifyToaster, resetData, autoSaveCalc }, thunkAPI) => {
        try {
            const response = await calculation.getCalculationDetail(finalData);
            if (!response) {
                notifyToaster(JSON.stringify(response), "error");
            }
            return { data: response, resetData: resetData, autoSaveCalc: autoSaveCalc };
        } catch (error) {
            notifyToaster(error.message, "error");
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateCalculationDetail = createAsyncThunk(
    "calculation/updateCalculationDetail",
    async ({ finalData, notifyToaster }, thunkAPI) => {
        try {
            const response = await calculation.updateCalculationDetail(finalData);
            if (!response) {
                notifyToaster(JSON.stringify(response), "error");
            }
            if (notifyToaster) {
                notifyToaster("Calculations updated", "success");
            }
            return response;
        } catch (error) {
            notifyToaster(error.message, "error");
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getIndexValue = createAsyncThunk(
    "calculation/getIndexValue",
    async ({ finalData, notifyToaster }, thunkAPI) => {
        try {
            const response = await calculation.getIndexValue(finalData);
            if (!response) {
                notifyToaster(JSON.stringify(response), "error");
            }
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            notifyToaster(message, "error");
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const calculationSlice = createSlice({
    name: "calculation",
    initialState,
    reducers: {
        clearData: (state) => {
            return initialState
        },
        resetData: (state) => {
            state.assetDCFParams = state.calculationDetail.assetDCFParams;
            state.assetHVLParams = state.calculationDetail.assetHVLParams;
            state.assetKPIs = state.calculationDetail.assetKPIs;
            state.calculationModified = false;
            state.kpisModified = false;
            state.assumptionsModified = false;
            state.rentRollAssumptions = {};
            state.assetValueMismatched = !compareAssetValue(state.calculationDetail, state.assetDCFResult);
        },
        setAssetDCFParams: (state, action) => {
            state.assetDCFParams = { ...state.assetDCFParams, ...action.payload }
            state.calculationModified = !deepObjectAsStringCompare(state.assetDCFParams, state.calculationDetail?.assetDCFParams)
        },
        setAssetHVLParams: (state, action) => {
            state.assetHVLParams = { ...state.assetHVLParams, ...action.payload }
            state.calculationModified = !deepObjectAsStringCompare(state.assetHVLParams, state.calculationDetail.assetHVLParams)
        },
        setRentRollAssumption: (state, action) => {
            state.rentRollAssumptions = action.payload
            state.assumptionsModified = !Object.keys(state.rentRollAssumptions).length === 0
        },
        setDashboardDetails:(state, action) => {
            state.assetAreaAndIncomeDetails = getAreaAndIncomeDetails(action.payload.rentRolls,action.payload.analysisDate, action.payload.useTypesCostsDefaults)
            state.assetTenantsDetails = getTenantDetails(action.payload.rentRolls,action.payload.analysisDate, action.payload.useTypesCostsDefaults)
            state.assetFinanceDetails = getFinanceDetails(action.payload.asset, action.payload.dcfResult, action.payload.dcfParams, action.payload.assetTypesCapRatesDefaults, action.payload.assetType)
        },
        setAssetCashflows:(state, action) => {
            state.assetCashflows = accumulateAssetCashflows(action.payload.assetId, action.payload.analysisDate, action.payload.durationInMonths, action.payload.dcfResult)
        },
        setAssetKPIs:(state, action) => {
            state.assetKPIs = calculateAssetKpis(action.payload.calculationDetail, action.payload.dcfResult, action.payload.assetDCFParams, action.payload.assetHVLParams, action.payload.assetTypesCapRatesDefaults, action.payload.landTaxes, action.payload.acquisitionCostsDefaults)
            state.kpisModified = !deepObjectAsStringCompare(state?.assetKPIs, state?.calculationDetail.assetKPIs)
        },
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCalculationDetail.pending, (state) => {
                state.isCalculationDetailLoading = true;
            })
            .addCase(getCalculationDetail.fulfilled, (state, action) => {
                state.isCalculationDetailLoading = false;
                state.isSuccess = true;
                state.calculationDetail = action.payload.data;
                if (action.payload.resetData) {
                    state.assetDCFParams = state.calculationDetail.assetDCFParams;
                    state.assetHVLParams = state.calculationDetail.assetHVLParams;
                    state.assetKPIs = state.calculationDetail.assetKPIs;
                    state.calculationModified = false;
                    state.kpisModified = false;
                    state.assumptionsModified = false;
                    state.rentRollAssumptions = {};
                    state.assetValueMismatched = !compareAssetValue(state.calculationDetail, state.assetDCFResult);
                }
                if (action.payload.autoSaveCalc) {
                    state.autoSaveCalc = action.payload.autoSaveCalc
                }
            })
            .addCase(getCalculationDetail.rejected, (state, action) => {
                state.isCalculationDetailLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            })
            .addCase(getAssetDCFResult.pending, (state) => {
                state.isAssetDCFCalcLoading = true;
            })
            .addCase(getAssetDCFResult.fulfilled, (state, action) => {
                state.isAssetDCFCalcLoading = false;
                state.isSuccess = true;
                state.assetDCFResult = action.payload;
                state.assetValueMismatched = !compareAssetValue(state.calculationDetail, action.payload)
            })
            .addCase(getAssetDCFResult.rejected, (state, action) => {
                state.isAssetDCFCalcLoading = false;
                state.isError = true;
                state.assetDCFResult = action.payload;
                state.statusMessage = action.payload;
            })
            .addCase(updateCalculationDetail.pending, (state) => {
                state.isCalculationDetailLoading = true;
            })
            .addCase(updateCalculationDetail.fulfilled, (state, action) => {
                state.isCalculationDetailLoading = false;
                state.isSuccess = true;
                state.calculationDetail = action.payload;
                state.assetDCFParams = action.payload.assetDCFParams;
                state.assetHVLParams = action.payload.assetHVLParams;
                state.assetKPIs = state.calculationDetail.assetKPIs;
                state.assetValueMismatched = !compareAssetValue(action.payload, state.assetDCFResult)
                state.rentRollAssumptions = {};
                state.calculationModified = false;
                state.kpisModified = false;
                state.assumptionsModified = false;
                state.autoSaveCalc = false;
            })
            .addCase(updateCalculationDetail.rejected, (state, action) => {
                state.isCalculationDetailLoading = false;
                state.isError = true;
                state.statusMessage = action.payload;
            });
    },
});

export const { clearData, resetData } = calculationSlice.actions;

export default calculationSlice.reducer;
