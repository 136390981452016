import {Button, Col, DatePicker, Form, Input, notification, Row, Select} from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getByTypeDropdown } from "../../../../../../app/features/Lookups/lookup.slice";
import {I18n} from "reia-rest-client";

export default function BasicData(props) {
  const [constructionDate, setConstructionDate] = useState("");
  const [refurbishmentDate, setRefurbishmentDate] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { assetTypes } = useSelector((state) => state.lookUps);
  const { next, handleCancel } = props;

  const { Option } = Select;
  // const { TextArea } = Input;

  const onFinish = (values) => {
    localStorage.setItem("form-1", JSON.stringify(values));
    next();
  };
  const localStorageData = localStorage.getItem("form-1");
  const formData = localStorageData ? JSON.parse(localStorageData) : null;

  useEffect(() => {
    const finalData = {
      catalog: "core-data",
      context: "assetTypes",
    };
    dispatch(getByTypeDropdown({finalData}));
  }, [dispatch]);
  
  const onChange = (date, dateString) => {
    if (refurbishmentDate && dateString > refurbishmentDate) {
      form.setFieldValue("yearsofRefurbishment", "");
      setError("Refurbishment year should be greater than Construction year");
    }
    setConstructionDate(dateString);
  };
  const onChange2 = (date, dateString) => {
    if (refurbishmentDate && constructionDate < dateString) {
      setError("");
    }
    setRefurbishmentDate(dateString);
  };

  return (
    <div className="pt-3 stepper-basic-data">
      <h3 className="font-weight-bold mb-0 mt-1 pt-4 text-blue">Basic Data</h3>
      <Form
        name="basic"
        className="user-modal basic-data"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        form={form}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row gutter={24} className="stepper-form">
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Asset ID"
              name="assetId"
              rules={[
                { required: false, message: "Please input your Asset ID!" },
              ]}
            >
              <Input placeholder="Asset id" disabled />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              name="assetType"
              initialValue={formData?.assetType ? formData?.assetType : null}
              label="Asset Type"
              rules={[
                { required: true, message: "Please input your Asset Type!" },
              ]}
            >
              <Select
                // type="name"
                // defaultValue={formData?.assetType ? formData?.assetType : ""}
                placeholder="Select"
                allowClear
              >
                {assetTypes?.map((item:I18n) => (
                  <Option key={item.id} value={item.id}>
                    {item.translations.en}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Asset Name"
              name="assetName"
              initialValue={formData?.assetName ? formData?.assetName : ""}
              rules={[
                { required: true, message: "Please input your Asset Name!" },
                {
                  max: 50,
                  message: "The max lenght of 50 Characters is reached!",
                },
              ]}
            >
              <Input
                // maxLength={50}
                // type="name"
                // defaultValue={formData?.assetName ? formData?.assetName : ""}
                placeholder="Enter asset name"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Years of Construction (average)"
              name="YearsofConstruction"
              initialValue={
                formData?.YearsofConstruction
                  ? moment(formData?.YearsofConstruction)
                  : ""
              }
              format="YYYY"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your Years of Construction!",
              //   },
              // ]}
            >
              <DatePicker
                onChange={onChange}
                picker="year"
                defaultValue="2020"
                className="w-100 rounded-sm"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Years of Refurbishment (average)"
              name="yearsofRefurbishment"
              format="YYYY"
              initialValue={
                formData?.yearsofRefurbishment
                  ? moment(formData?.yearsofRefurbishment)
                  : ""
              }
              rules={[
                {
                  required: false,
                  message: "Please input your Years of Refurbishment!",
                },
              ]}
            >
              <DatePicker
                onChange={onChange2}
                disabled={!constructionDate}
                disabledDate={(current) => {
                  const disabledDate = `${constructionDate}-01-01`;
                  return (
                    current && current < moment(disabledDate, "DD.MM.YYYY")
                  );
                }}
                picker="year"
                className="w-100 rounded-sm"
              />
            </Form.Item>
            <div className="text-danger">{error}</div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              label="Analysis Date"
              name="analysisDate"
              format="YYYY"
              initialValue={
                formData?.analysisDate ? moment(formData?.analysisDate) : ""
              }
              rules={[
                { required: true, message: "Please input your Analysis Date!" },
              ]}
            >
              <DatePicker
                // type="name"
                format="DD.MM.YYYY"
                defaultValue={
                  formData?.analysisDate ? moment(formData?.analysisDate) : ""
                }
                picker="date"
                className="w-100 rounded-sm"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="stepper-action-btn">
          <Col xs={24}>
            <div className="steps-action d-flex justify-content-between align-items-center mt-3">
              <div>
                <Button
                  style={{
                    margin: "0 8px",
                  }}
                  className="btn-cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className="btn-primary"
                  htmlType="submit"
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
BasicData.propTypes = {
  next: PropTypes.node.isRequired,
  handleCancel: PropTypes.node.isRequired,
};
