import { Col, Row } from 'antd';
import React from 'react'
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from '../../../../../../../utils/digitHelper';
import DataTable from '../../../../../../../components/Layout/Table/DataTable';
import ReactApexChart from 'react-apexcharts';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';

const TenantAnalysis = ({ assetTenantsDetails }) => {
    const tenantShare = {
        series: [assetTenantsDetails?.shareIncomeTop5 ?? 0],
        options: {
            chart: {
                height: 120,
                width: 100,
                type: 'radialBar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                        margin: 0,
                        size: '67%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                    },
                    track: {
                        background: '#F2F4F7',
                        strokeWidth: '90%',
                        margin: 0,
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: -8,
                            show: true,
                            color: '#101828',
                            fontSize: '8px'
                        },
                        value: {
                            formatter: function (val) {
                                return `${parseInt(val)}`;
                            },
                            color: '#101828',
                            offsetY: -6,
                            fontSize: '12px',
                            fontWeight: 600,
                            show: true,
                        }
                    }
                }
            },
            fill: {
                colors: ['#2C406F'],
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['Total'],
        },
    };

    const prolongation = {
        series: [assetTenantsDetails?.shareIncomeProlongation ?? 0],
        options: {
            chart: {
                height: 120,
                width: 100,
                type: 'radialBar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 225,
                    hollow: {
                        margin: 0,
                        size: '67%',
                        background: '#fff',
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: 'front',
                    },
                    track: {
                        background: '#F2F4F7',
                        strokeWidth: '90%',
                        margin: 0,
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#101828',
                            fontSize: '8px'
                        },
                        value: {
                            formatter: function (val) {
                                return `${parseInt(val)}`;
                            },
                            color: '#101828',
                            offsetY: -6,
                            fontSize: '12px',
                            fontWeight: 600,
                            show: true,
                        }
                    }
                }
            },
            fill: {
                colors: ['#FF7228'],
            },
            stroke: {
                lineCap: 'round'
            },
            labels: ['Total'],
        },
    };
    const topTenantsColumns = [
        {
            id: 0,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>No</p>,
            align: "center",
            children: [{
                dataIndex: 'key',
                width: "80px",
                title: "",
                align: "center",
                render: (text, record, index) => <span>{record.tenantName ? index + 1 : 'Total'}</span>
            }]
        },
        {
            id: 1,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Top 5 Tenants</p>,
            align: "center",
            children: [{
                dataIndex: "tenantName",
                width: "260px",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Name</p>,
                align: "center",
            }]
        },
        {
            id: 2,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Share</p>,
            align: "center",
            children: [{
                dataIndex: "shareIncome",
                align: "center",
                width: "100px",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Icome %</p>,
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit' }}>
                        {text ? getFormattedDecimalNumber(text * 100) : null}
                    </span>
                ),
            }]
        },
        {
            id: 3,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Lettable</p>,
            align: "center",
            children: [{
                dataIndex: "totalArea",
                width: "100px",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Area sq</p>,
                align: "center",
            }]
        },
        {
            id: 4,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Gross Rental Income</p>,
            align: "center",
            children: [{
                dataIndex: "grossRentalIncomeYear",
                width: "145px",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Current Rate (EUR p.a)</p>,
                align: "right",
            }, {
                dataIndex: "grossRentalIncomeSqm",
                width: "100px",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit' }}>
                        {text ? getFormattedEnglishNumber(text) : "N/A"}
                    </span>
                ),
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>(EUR/sqm)</p>,
                align: "right",
            }]
        },
        {
            id: 6,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>Expiry</p>,
            align: "center",
            children: [{
                render: (text) => (
                    <span>
                        {text ? dayjs(text).format("DD.MM.YYYY") : "N/A"}
                    </span>
                ),
                width: "140px",
                dataIndex: "expiry",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Date</p>,
                align: "center",
            }]
        },
        {
            id: 7,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>WALT</p>,
            align: "center",
            children: [{
                dataIndex: "walt",
                render: (text) => (
                    <span style={{ color: text < 0 ? 'red' : 'inherit' }}>
                        {text ? getFormattedDecimalNumber(text) : "N/A"}
                    </span>
                ), width: "100px",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>Year</p>,
                align: "center",
            }]
        },
        {
            id: 8,
            hidden: false,
            title: () => <p className='mb-0' style={{ fontSize: '12px', fontWeight: 600, color: "#01215D" }}>{`Options (Years)`}</p>,
            align: "center",
            children: [{
                dataIndex: "option1",
                render: (text) => (
                    <span>
                        {text ? dayjs(text).format('YYYY') : "N/A"}
                    </span>
                ), width: "80px",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>1st Year</p>,
                align: "left",
            }, {
                dataIndex: "option2",
                render: (text) => (
                    <span>
                        {text ? dayjs(text).format('YYYY') : "N/A"}
                    </span>
                ), width: "80px",
                title: () => <p className='mb-0' style={{ fontSize: '12px', color: '#1F1F1F', fontStyle: "italic" }}>2nd Year</p>,
                align: "right",
            }]
        },
    ];

    let topTenantsData = [...(assetTenantsDetails?.rankedTenants ?? [])];
    let totalValues = assetTenantsDetails?.totalValues;

    if (totalValues) {
        let newUseTypeTotal = { ...totalValues };
        topTenantsData.push(newUseTypeTotal);
    }
    return (
        <div>
            <Row gutter={[20, 20]} style={{ paddingBottom: '20px' }}>
                <Col xs={24} lg={12}>
                    <div className='custom-card d-flex justify-content-between align-items-center'>
                        <div>
                            <h2 style={{ fontSize: '24px' }}>Total Tenants</h2>
                            <h2 style={{ fontSize: '24px' }}>{assetTenantsDetails?.totalTenants}</h2>
                        </div>
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    <div className='custom-card d-flex justify-content-between align-items-center'>
                        <div>
                            <h2 style={{ fontSize: '24px' }}>Total Tenants <span style={{ fontSize: '20px' }}>(excluding units)</span></h2>
                            <h2 style={{ fontSize: '24px' }}>{assetTenantsDetails?.totalTenantsExcludingUnits}</h2>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='custom-card' style={{ height: '488px' }}>
                <h2 style={{ fontSize: '24px' }}>Top Tenants Analysis</h2>
                <DataTable
                    columns={topTenantsColumns.filter((item) => item.hidden === false)}
                    dataSource={topTenantsData ?? []}
                    pagination={false}
                    className="antd-table-striped asset-dashbaord-table"
                    scroll={{
                        x: "1100px",
                        y: "calc(100vh - 372px)",
                    }}
                />
            </div>
            <Row gutter={20} style={{ paddingTop: '20px' }}>
                <Col xs={24} lg={12}>
                    <div className='custom-card d-flex justify-content-between align-items-center' style={{ paddingBottom: '32px' }}>
                        <div>
                            <h2 style={{ fontSize: '24px' }}>Top 5 Tenants Share of
                                Income</h2>
                        </div>
                        <ReactApexChart options={tenantShare.options} series={tenantShare.series} type="radialBar" height={120} width={100} />
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    <div className='custom-card d-flex justify-content-between align-items-center' style={{ paddingBottom: '32px' }}>
                        <div>
                            <h2 style={{ fontSize: '24px' }}>Share of Income with Prolongation Options</h2>
                        </div>
                        <ReactApexChart options={prolongation.options} series={prolongation.series} type="radialBar" height={120} width={100} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TenantAnalysis