import { Col, Progress, Row, Typography } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useSelector } from "react-redux";
import { getFormattedDecimalNumber, getFormattedEnglishNumber, getFormattedInt, getFormattedNumber } from "../../../../../../utils/digitHelper";
import { getPortfolioAssetValue, getPortfolioTerminalValues, getPortfolioTotalLettableArea, getPortfolioVacancyRate, getPortfolioWALTYears, getPortfolioYieldProfileAtSaleCalculations, getPortfolioYieldProfileCalculations, grossOperatingIncomeInMonth, marketRentInMonth, netOperatingIncomeInMonth, nonRecoverableCostInMonth, otherIncomCostBeforeNOIInMonth, potentialRentInMonth } from "../../../../../../utils/portfolioHelper";

export default function PortfolioDetailDrawer(props) {
  const [isActive, setIsActive] = useState(false);
  const { landTaxes, acquisitionCostsDefaults, assetTypesCapRatesDefaults, useTypesCostsDefaults } = useSelector(state => state.settings)

  const tabHandleClick = (tab) => {
    setIsActive(tab);
  };
  const {
    setShowDrawer,
    showDrawer,
    portfolioData,
    calculationDetail,
  } = props;
  const { portfolio, calculationDetailsByPortfolio, isLoading, isSuccess, calculationModified, dcfResultsModified, portfolioValueMismatched, autoSaveCalc, portfolioDCFParams } = useSelector((state) => state.portfolio);
  const portfolioAssetValue = getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified);
  // **********************

  //*****************Finding Total lettable area
  const totalLettableArea = getPortfolioTotalLettableArea(calculationDetailsByPortfolio)

  //*****************Rental per anum
  const currentRental = grossOperatingIncomeInMonth(calculationDetailsByPortfolio) * 12;

  const marketRental = marketRentInMonth(calculationDetailsByPortfolio) * 12;

  const potentialRental = potentialRentInMonth(calculationDetailsByPortfolio) * 12;

  //*****************Finding Vacancy Rate
  const vacancyRate = getPortfolioVacancyRate(calculationDetailsByPortfolio)

  //*****************Gross and Net capital/terminal value @ sale
  const accValues = getPortfolioTerminalValues(calculationDetailsByPortfolio, dcfResultsModified, isActive, landTaxes, acquisitionCostsDefaults);

  //*****************Finding Yiel Profile
  const { yielCurrentNIY, yielCurrentGIY, yielPotentialNIY, yielPotentialGIY, yielMarketNIY, yielMarketGIY } = getPortfolioYieldProfileCalculations(calculationDetailsByPortfolio, dcfResultsModified, isActive, landTaxes, acquisitionCostsDefaults)

  //*****************Finding Yiel Profile @ sale
  const { yielSaleCurrentNIY, yielSaleCurrentGIY, yielSalePotentialNIY, yielSalePotentialGIY, yielSaleMarketNIY, yielSaleMarketGIY } = getPortfolioYieldProfileAtSaleCalculations(calculationDetailsByPortfolio, dcfResultsModified, isActive, landTaxes, acquisitionCostsDefaults)

  //*****************WALT Calculations
  const waltYears = getPortfolioWALTYears(calculationDetailsByPortfolio)

  return (
    <div
      className="custom-drawer-content bg-white rounded-sm"
    // style={{ position: "fixed", width: "-webkit-fill-available" }}
    >
      <div
        onClick={() => setShowDrawer(!showDrawer)}
        className="sidebar-collapse-button cursor-pointer"
      >
        <MdOutlineKeyboardArrowRight size={25} fill="#fff" />
      </div>
      <div className="custom-drawer asset-detial-drawer custom-scroll" >
        {/*  p-3 */}
        <div className="drawer_text p-3">
          <h4>{portfolioData?.portfolioName}</h4>
          {/* <p className="mb-0">
            {assetData?.assetType?.translations?.en || "N/A"}
          </p>
          <p className="mb-0">
            {`${assetData?.address?.street}, ${assetData?.address?.streetNo}, ${assetData?.address?.city?.translations?.en}, ${assetData?.address?.federalState?.translations?.en}, ${assetData?.address?.country?.translations?.en}` ||
              "N/A"}
          </p> */}
        </div>

        <div className="area-percentage__bar p-3">
          <Row gutter={24} style={{ justifyContent: "space-between" }}>
            <Col xs={4}>
              <p>Area</p>
            </Col>
            <Col xs={16}>
              <p className="text-center">{getFormattedEnglishNumber(totalLettableArea)}</p>
            </Col>
            <Col xs={4}>
              <p>sqm</p>
            </Col>
          </Row>

          <Row gutter={24} style={{ justifyContent: "space-between" }}>
            <Col xs={4}>
              <h6>Occup.</h6>
            </Col>

            <Col xs={16}>
              <div className="drawer-progress">
                <Progress
                  percent={getFormattedEnglishNumber(vacancyRate * 100)}
                  format={(percent) => percent}
                  size="small"
                />
              </div>
            </Col>
            <Col xs={4}>
              <h6>%</h6>
            </Col>
          </Row>

          <Row gutter={24} style={{ justifyContent: "space-between" }}>
            <Col xs={4}>
              <h6>WALT.</h6>
            </Col>
            <Col xs={16}>
              <div className="drawer-progress">
                <Progress
                  percent={getFormattedEnglishNumber(waltYears)}
                  format={(percent) => percent}
                  size="small"
                />
              </div>
            </Col>
            <Col xs={4}>
              <h6>years</h6>
            </Col>
          </Row>
        </div>
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
            <h4 >Asset Value</h4>
            <h4 >{getFormattedEnglishNumber(portfolioAssetValue)} EUR</h4>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
            <h5 style={{ color: '#1F1F1F', }}>per sq m</h5>
            <h5 style={{ color: '#1F1F1F', }}>
              {/* {'assetValuePSM'} */}
              {getFormattedEnglishNumber(accValues?.grossCapitalValue)} EUR</h5>
          </div>
        </div>
        <div className="drawer-tabs px-3 pb-3" style={{ borderBottom: '1px solid #E6E9EF' }}>
          <div className="drawer-tab__list">
            <button
              onClick={() => {
                tabHandleClick(false);
              }}
              className={!isActive ? "active" : ""}
            >
              Analysis Date
            </button>
            <button
              onClick={() => {
                tabHandleClick(true);
              }}
              className={isActive ? "active" : ""}
            >
              Exit
            </button>
          </div>
          <div className="drawer-tab__content px-1">
            {!isActive && (
              <>
                {/* <h5 className="mb-0 text-black pt-2">Yiel Profile</h5> */}
                <div
                  className="pt-2"
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "end",
                    justifyContent: "space-between",
                    paddingRight: "5px",
                  }}
                >
                  <div>
                    <h4 className="mb-0 text-black">Yield Profile</h4>
                    <h5 className="mb-0 text-black font-weight-normal">@Current</h5>
                    <h5 className="mb-0 text-black font-weight-normal">@Potential</h5>
                    <h5 className="mb-0 text-black font-weight-normal">@Market</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">NIY</h4>
                    <Typography>
                      {getFormattedDecimalNumber(yielCurrentNIY * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(yielPotentialNIY * 100)}%
                    </Typography>
                    <Typography>{getFormattedDecimalNumber(yielMarketNIY * 100)}%</Typography>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">GIY</h4>
                    <Typography>
                      {getFormattedDecimalNumber(yielCurrentGIY * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(yielPotentialGIY * 100)}%
                    </Typography>
                    <Typography>{getFormattedDecimalNumber(yielMarketGIY * 100)}%</Typography>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">M</h4>
                    <Typography>{getFormattedDecimalNumber(1 / yielCurrentGIY)}</Typography>
                    <Typography>{getFormattedDecimalNumber(1 / yielPotentialGIY)}</Typography>
                    <Typography>{getFormattedDecimalNumber(1 / yielMarketGIY)}</Typography>
                  </div>
                </div>
              </>
            )}
            {isActive && (
              <>
                {/* <h4 className="mb-0 text-black pt-2">Yiel Profile @ Sale</h4> */}
                <div
                  className="pt-2"
                  style={{
                    display: "flex",
                    gap: "9px",
                    alignItems: "end",
                    justifyContent: "space-between",
                    paddingRight: "5px",
                  }}
                >
                  <div>
                    <h4 className="mb-0 text-black">Yield Profile @ Sale</h4>
                    <h5 className="mb-0 text-black font-weight-normal">@Current</h5>
                    <h5 className="mb-0 text-black font-weight-normal">@Potential</h5>
                    <h5 className="mb-0 text-black font-weight-normal">@Market</h5>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">NIY</h4>
                    <Typography>
                      {getFormattedDecimalNumber(yielSaleCurrentNIY * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(yielSalePotentialNIY * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(yielSaleMarketNIY * 100)}%
                    </Typography>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">GIY</h4>
                    <Typography>
                      {getFormattedDecimalNumber(yielSaleCurrentGIY * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(yielSalePotentialGIY * 100)}%
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(yielSaleMarketGIY * 100)}%
                    </Typography>
                  </div>
                  <div>
                    <h4 className="mb-0 text-black">M</h4>
                    <Typography>
                      {getFormattedDecimalNumber(1 / yielSaleCurrentGIY)}
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(1 / yielSalePotentialGIY)}
                    </Typography>
                    <Typography>
                      {getFormattedDecimalNumber(1 / yielSaleMarketGIY)}
                    </Typography>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="p-3" style={{ borderBottom: "1px solid #E6E9EF" }}>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
            <h4 className="mb-0">Rental Level:</h4>
            <h4 className="mb-0">-15%</h4>
          </div>
          <h5 className="font-weight-normal">Undemented</h5>
          <div className="pt-2">
            <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
              <h5 className=" text-black font-weight-normal">Current Rent p.a.</h5>
              <h5 className=" text-black font-weight-normal">{getFormattedEnglishNumber(currentRental)} EUR</h5>
            </div>
            <Row gutter={24} style={{ justifyContent: "space-between" }}>
              <Col xs={16}>
                <div className="drawer-rent-progress">
                  <Progress
                    percent={currentRental}
                    format={(_) => ""}
                    style={{ "& .ant-progress-outer": { padding: 0 } }}
                    size="small"
                  />
                </div>
              </Col>
              <Col xs={8} className="d-flex justify-content-end">
                <h6 className="font-size-sm" style={{ color: '#9B9B9B' }}>
                  {getFormattedEnglishNumber(calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
                    ?.totalGrossRentalIncome[0])} EUR</h6>
              </Col>
            </Row>
          </div>
          <div className="pt-2">
            <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
              <h5 className=" text-black font-weight-normal">Market Rent p.a.</h5>
              <h5 className=" text-black font-weight-normal">{getFormattedEnglishNumber(marketRental)} EUR</h5>
            </div>
            <Row gutter={24} style={{ justifyContent: "space-between" }}>
              <Col xs={16}>
                <div className="drawer-rent-progress">
                  <Progress
                    percent={marketRental}
                    format={(_) => ""}
                    size="small"
                  />
                </div>
              </Col>
              <Col xs={8} className="d-flex justify-content-end">
                <h6 className="font-size-sm" style={{ color: '#9B9B9B' }}>
                  {getFormattedEnglishNumber(calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
                    ?.totalMArketRent[0])} EUR</h6>
              </Col>
            </Row>
          </div>
          <div className="pt-2">
            {/* <h5 className="mb-0 text-black font-weight-normal">Potential Rent p.a.</h5>
              <p className="mb-0 text-gray ">
                {`${potentialRental} EUR` || "N/A"}
              </p> */}
            <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
              <h5 className=" text-black font-weight-normal">Potential Rent p.a.</h5>
              <h5 className=" text-black font-weight-normal">{getFormattedEnglishNumber(potentialRental)} EUR</h5>
            </div>
            <Row gutter={24} style={{ justifyContent: "space-between" }}>
              <Col xs={16}>
                <div className="drawer-rent-progress">
                  <Progress
                    percent={potentialRental}
                    format={(_) => ""}
                    size="small"
                  />
                </div>
              </Col>
              <Col xs={8} className="d-flex justify-content-end">
                <h6 className="font-size-sm" style={{ color: '#9B9B9B' }}>
                  {getFormattedEnglishNumber(calculationDetail?.assetDCFResult?.dcfResult?.assetCashFlow
                    ?.totalPotentialRent[0])} EUR</h6>
              </Col>
            </Row>
          </div>
        </div>
        {/* <hr className="my-2" />*/}
        <div className="p-3" style={{ borderBottom: "1px solid #E6E9EF" }}>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">Leveraged IRR</h5>
            <h5 className="mb-0 text-black font-weight-normal">{`12.50  %` || "N/A"}</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap pt-2" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">Cash on Cash</h5>
            <h5 className="mb-0 text-black font-weight-normal">{`5.64  %` || "N/A"}</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap pt-2" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">WACC</h5>
            <h5 className="mb-0 text-black font-weight-normal">{`6.80  %` || "N/A"}</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap pt-2" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">Equity Multiple</h5>
            <h5 className="mb-0 text-black font-weight-normal">{`4.01  %` || "N/A"}</h5>
          </div>
        </div>
        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">Analysis Date:</h5>
            <h5 className="mb-0 text-black font-weight-normal">{dayjs(calculationDetail?.analysisDate).format("DD.MM.YYYY") || "N/A"}</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap pt-2" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">Last Change</h5>
            <h5 className="mb-0 text-black font-weight-normal">{"1/04/2024" || "N/A"}</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center flex-sm-wrap pt-2" style={{ gap: '10px' }}>
            <h5 className="mb-0 text-black font-weight-normal">User</h5>
            <h5 className="mb-0 text-black font-weight-normal">{"JB" || "N/A"}</h5>
          </div>
        </div>
      </div>
    </div >
  );
}

PortfolioDetailDrawer.propTypes = {
  setShowDrawer: PropTypes.node.isRequired,
  showDrawer: PropTypes.node.isRequired,
};
