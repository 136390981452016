/* eslint-disable */

import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Space,
  Tag,
} from "antd";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { BsBell } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import IMAGES from "../../../assets/images";
import { getRoleName } from "../../../utils/authenticateUsers";
import { getPageInfo } from "../../../utils/helpers";
import PATH from "../../../utils/path";
import SIDE_MENU_LIST from "../SideMenu/SideMenuItems";

const { Header, Content, Sider } = Layout;
function AppLayout({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const param = useParams();
  const pageInfo = getPageInfo(location.pathname);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const role = getRoleName(user?.roles);
  // console.log(role, user, "user role");
  const logoutFun = () => {
    dispatch({ type: "auth/logout" });
    auth.signoutRedirect();
  };
  let sideMenuItems = [];
  const { project } = useSelector((state) => state.projects);
  const { asset } = useSelector((state) => state.assets);
  const { portfolio } = useSelector((state) => state.portfolio);

  if (user && role === "admin")
    sideMenuItems = [...SIDE_MENU_LIST.ADMIN, ...sideMenuItems];
  if (user && role === "corporate")
    sideMenuItems = [...SIDE_MENU_LIST.CORPORATE, ...sideMenuItems];
  if (user && role === "individual")
    sideMenuItems = [...SIDE_MENU_LIST.INDIVIDUAL, ...sideMenuItems];
  if (user && role === "projectManager")
    sideMenuItems = [...SIDE_MENU_LIST.PROJECT_MANAGER, ...sideMenuItems];
  const profileDropdown = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Link className="text-dark" to={PATH.PROFILE_SETTING}>
              Profile Setting
            </Link>
          ),
        },
        {
          key: "2",
          label: (
            <Link
              className="text-dark"
              to={location.pathname}
              onClick={logoutFun}
            >
              Logout
            </Link>
          ),
        },
      ]}
    />
  );
  // const footerMenuItems = [
  //   // {
  //   //   // key: PATH.ADMIN_DASHBOARD,
  //   //   icon: <img src={IMAGES.HELP_ICON} alt="" />,
  //   //   label: (
  //   //     <Link to={location.pathname} onClick={logoutFun}>
  //   //       Help
  //   //     </Link>
  //   //   ),
  //   // },
  //   {
  //     // key: PATH.ADMIN_PROJECTS,
  //     icon: <img src={IMAGES.LOGOUT} alt="" />,
  //     label: (
  //       <Link to={location.pathname} onClick={logoutFun}>
  //         Logout
  //       </Link>
  //     ),
  //   },
  // ];
  const getActualParam = (item) => {
    const actualParam = param[item.param];
    let actualPath = item.path.replace(`:${item.param}`, actualParam);
    let ptrn = /:[a-zA-Z]*/gm;

    let match;
    while ((match = ptrn.exec(actualPath)) != null) {
      const nextParam = param[match[0].replace(":", "")];
      actualPath = actualPath.replace(match[0], nextParam);
    }

    return actualPath;
  };

  useEffect(() => {
    setCollapsed(true);
  }, [location.pathname]);

  console.log(portfolio, 'portfolio')
  return (
    <Layout hasSider>
      <Sider
        collapsible
        trigger={null}
        collapsed={collapsed}
        className="antd-custom-sidebar"
        width="235"
      >
        <div
          className="sidebar-collapse-button cursor-pointer"
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? (
            <MdOutlineKeyboardArrowRight fill="#fff" size={25} />
          ) : (
            <MdOutlineKeyboardArrowLeft fill="#fff" size={25} />
          )}
        </div>
        <div className="sidebar-inner-section">
          <div className="w-100">
            <div
              className="text-center user-info-section cursor-pointer"
              role="button"
              tabIndex="0"
              style={{ paddingTop: '28px', paddingBottom: '20px' }}
              onClick={() => navigate('/projects')}
            >
              {/* <Link to='#'> */}
              <img
                style={{ width: collapsed ? "40px" : "130px", height: '40px' }}
                src={collapsed ? IMAGES.CORECAP_FAVICON : IMAGES.CORECAP_LOGO}
                alt=""
              />{" "}
              {/* </Link> */}
            </div>
            <div className="custon-menu">
              <Menu
                className="mt-2 custom-antd-menu custom-scroll"
                theme="dark"
                mode="inline"
                defaultSelectedKeys={["STUDENT_DASHBOARD"]}
                selectedKeys={[
                  (location.pathname.startsWith("/projects") && "/projects") ||
                  (location.pathname.startsWith("/user-management") &&
                    "/user-management") ||
                  (location.pathname.startsWith(`/${role}/dashboard`) &&
                    `${role}/dashboard`) ||
                  (location.pathname.startsWith("/parameters") &&
                    "/parameters"),
                ]}
                items={sideMenuItems}
                defaultOpenKeys={[location?.pathname?.split("/")?.[2] || ""]}
              />
            </div>
          </div>
        </div>
      </Sider>
      <div className="container p-0 w-100 h-100">
        <Layout style={{ marginLeft: collapsed ? "80px" : "235px" }}>
          <Header
            className="site-layout-sub-header-background custom-header d-flex align-items-center justify-content-between px-4"
            style={{
              padding: 0,
              position: "sticky",
              top: 0,
              zIndex: 9,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <h3 className="header-page-title mb-0 text-black-50 d-flex font-weight-light">
                {pageInfo?.pages?.map((item, index) => {
                  console.log(item.title, 'page info item in layout')
                  return <>
                    <Link
                      className={` mx-1 ${pageInfo?.pages.length === index + 1 &&
                        "activeBreadcrumbs"
                        } `}
                      to={item.param ? getActualParam(item) : item.path}
                    >
                      {
                        item.title === 'Project Details' ?
                          project?.projectName :
                          item.title === 'Asset Details' ?
                            asset?.assetName :
                            item.title === "Portfolio Details" ?
                              portfolio?.portfolioName : item.title
                      }
                    </Link>
                    {pageInfo?.pages.length !== index + 1 && "/"}
                  </>
                })}
              </h3>
            </div>
            {user &&
              user.roleId &&
              user.roleId === 2 &&
              location.pathname.startsWith(
                `/user-management/${param.userId}/user-details`
              ) ? (
              <Tag fill="#00215b" className="user-banner">
                <h5 className="mb-0 text-white">You are Viewing as Appostel</h5>
              </Tag>
            ) : (
              ""
            )}
            <div className="align-items-center d-flex">
              {/* <Badge dot fill="#FF3636">
                <BsBell size={20} className="cursor-pointer" />
              </Badge>

              <Divider type="vertical" /> */}
              <Dropdown overlay={profileDropdown} trigger={["click"]}>
                <Space>
                  <Avatar
                    src={`https://ui-avatars.com/api/?name=${`${user.username}`}&background=00215b&color=fff`}
                    size={35}
                    className="cursor-pointer"
                    icon={<UserOutlined />}
                  />
                  <h5 className="mb-0 text-black cursor-pointer">
                    {user.username}
                  </h5>
                  <IoIosArrowDown fill="black" className="cursor-pointer" />
                </Space>
              </Dropdown>
            </div>
          </Header>
          <Content>
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 360,
              }}
            >
              {children}
            </div>
          </Content>
        </Layout>
      </div>
    </Layout>
  );
}
AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AppLayout;
