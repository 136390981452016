import { useEffect, useState } from 'react';
import { InputNumber } from 'antd';

const FormattedInputNumber = ({ className, value, onChange, formatter, placeholder, min, max, disabled, ...rest }) => {
    const [inputValue, setInputValue] = useState(value);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
        // Apply the formatter when the input loses focus
        if (formatter && inputValue !== undefined && inputValue !== '') {
            const formattedValue = formatter(inputValue);
            setInputValue(formattedValue);  // Keep the formatted value in the state
            if (onChange) {
                onChange(parseFloat(formattedValue)); // Pass the numeric value to the parent
            }
        }
    };
    console.log(inputValue, 'inputValue')
    console.log(typeof inputValue, 'typeof inputValue')

    const handleChange = (newValue) => {
        // Allow input only if it's a valid number or an empty string (to allow erasing)
        const valueString = String(newValue);

        // Check if the input already contains more than one decimal point
        const hasMultipleDots = valueString.split('.').length > 2;

        if (!hasMultipleDots) {
            setInputValue(newValue); // Allow raw value during typing
            if (onChange) {
                onChange(newValue); // Pass raw value as the user types
            }
        }
    };

    return (
        <InputNumber
            className={`removeStyles ${className}`}
            disabled={disabled}
            controls={false}
            min={min}
            max={max}
            value={inputValue}
            // Display raw input when focused, formatted value on blur
            formatter={(rawValue) => (isFocused ? rawValue : formatter ? formatter(rawValue) : rawValue)}
            placeholder={formatter ? formatter(placeholder) : placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            {...rest}
        />
    );
};

export default FormattedInputNumber;


// import { InputNumber } from 'antd';
// import { useState } from 'react';

// const FormattedInputNumber = ({ className, value, onChange, formatter, placeholder, min, max, disabled, ...rest }) => {
//     const [isFocused, setIsFocused] = useState(false);

//     const handleFocus = () => {
//         setIsFocused(true);
//     };

//     const handleBlur = () => {
//         setIsFocused(false);
//         // Apply the formatter when the input loses focus
//         if (formatter && value !== undefined && value !== '') {
//             const formattedValue = formatter(value);
//             if (onChange) {
//                 onChange(parseFloat(formattedValue)); // Pass the numeric value to the parent
//             }
//         }
//     };

//     const handleChange = (newValue) => {
//         // Allow input only if it's a valid number or an empty string (to allow erasing)
//         const valueString = String(newValue);

//         // Check if the input already contains more than one decimal point
//         const hasMultipleDots = valueString.split('.').length > 2;

//         if (!hasMultipleDots && onChange) {
//             onChange(newValue); // Pass raw value as the user types
//         }
//     };

//     return (
//         <InputNumber
//             className={`removeStyles ${className}`}
//             disabled={disabled}
//             controls={false}
//             min={min}
//             max={max}
//             value={value} // Use the value directly from props
//             // Display raw input when focused, formatted value on blur
//             formatter={(rawValue) => (isFocused ? rawValue : formatter ? formatter(rawValue) : rawValue)}
//             placeholder={formatter ? formatter(placeholder) : placeholder}
//             onFocus={handleFocus}
//             onBlur={handleBlur}
//             onChange={handleChange}
//             {...rest}
//         />
//     );
// };

// export default FormattedInputNumber;
