
let numberFormat = new Intl.NumberFormat();

export const getFormattedNumber = (number) => {
    return numberFormat.format(number)
}


export const getFormattedInt = (number) => {
    return numberFormat.format(parseInt(number))
}

export function getFormattedEnglishNumber(number) {
    if (number === null || number === undefined) {
        return null; // return null if the input is null or undefined
    }

    if (typeof number === 'string') {
        number = number.replace(/,/g, ''); // Remove commas if any
    }

    const parsedNumber = parseFloat(number);

    if (Number.isNaN(parsedNumber)) {
        return null; // return null if parsing fails
    }

    const engFormat = Number(parsedNumber?.toFixed(0))?.toLocaleString('en-US');
    return engFormat;
}


export function getFormattedDecimalNumber(value) {
    if (value === null || value === undefined) {
        return null; // return null or undefined as is
    }

    // If it's a string, try to parse it into a number
    if (typeof value === 'string') {
        const parsedValue = parseFloat(value);
        if (Number.isNaN(parsedValue)) {
            return null; // return null if the string cannot be parsed into a valid number
        }
        return parsedValue.toFixed(2); // format the parsed number
    }

    // For non-string types, check if it's a valid number
    if (typeof value !== 'number') {
        return null; // return null for non-number types that aren't strings
    }

    return value.toFixed(2); // format the number
}

export function getFormattedDecimalNumberOrNull(number) {
    if (number === null || number === undefined || Number.isNaN(number) || number.length === 0) {
        return null
    }

    return getFormattedDecimalNumber(number);
}
