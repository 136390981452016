import { Button, Card, Col, Row, Switch } from "antd";
import React, { useState } from "react";
import { Select } from "antd";
import { Input } from "antd";
import { FaPlus } from "react-icons/fa6";
import { AiOutlinePercentage } from "react-icons/ai";
import IMAGES from "../../../../../../../../assets/images";
import { CiUndo } from "react-icons/ci";
import IncDecCounter from "../../../../../../../../components/Layout/IncDecCounter";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDecimalNumber, getFormattedEnglishNumber } from "../../../../../../../../utils/digitHelper";
import {
    DCF_RENT_ROLL_HVL_OTHER_TERM, DCF_RENT_ROLL_INITIAL_RENEWAL_PROB,
    DCF_RENT_ROLL_LEASE_DURATION_NEW_LEASES, DCF_RENT_ROLL_TENANT_IMPROVEMENT_INITIAL, DCF_RENT_ROLL_VOID_PERIOD_INITIAL
} from "../../../../../../../../utils/dcfDefaults";

function VhlCashflowAssumption() {
    const dispatch = useDispatch();
    const {
        totalLettableArea,
        currentRentSQM,
        currentRentMonthly,
        currentRentYearly,
        occupiedArea,
        leaseDuration,
        option1InYears,
        marketLease,
        marketRentMonthly, voidPeriodInitial, initialTenantImprovements,
        marketRentSQM,
        marketRentYearly,
        renewalProbability,
        indexationAdjustmentFactor,
        indexationType,
        otherCosts
    } = useSelector((state) => state.calculation.assetHVLParams);
    const [isSwitch, setIsSwitch] = useState(indexationType === "Ja" ? true : false);
    const setAssetHVLParams = (params) => {
        dispatch({ type: "calculation/setAssetHVLParams", payload: params })
    }

    const switchChange = (checked) => {
        setIsSwitch(checked);
        setAssetHVLParams({ indexationType: checked ? "Ja" : "Nein" })
    };
    return (
        <div>
            <Card bordered={false} className="assets_card cashflow-assumption-card">
                <div className="assets_card__header mb-2 pb-2 d-flex justify-content-between align-items-center" style={{ gap: '20px', flexWrap: 'wrap' }}>
                    <h3 className="text-black">Cash Flow Assumptions</h3>
                    <Button className="d-flex justify-content-center align-items-center" style={{ width: "48px", height: '44px', padding: 0 }}
                    // onClick={(e) => setDefaultParams(landTaxes, acquisitionCostsDefaults)}
                    >
                        <CiUndo size={34} />
                    </Button>
                </div>

                <Row gutter={24} className="mb-2 assumption_fields pt-2 align-items-center">
                    <Col xs={12}>
                        <Row className="align-items-center justify-content-between">
                            <Col xs={12}>
                                <h4 className="mb-0 text-black-50 inner_progress">Market Lease Team</h4>
                                <h6 style={{ color: '#797979' }}>Months</h6>
                            </Col>
                            <Col xs={12}>
                                <IncDecCounter placeholder={DCF_RENT_ROLL_LEASE_DURATION_NEW_LEASES / 12} value={marketLease} formatter={getFormattedEnglishNumber} setIncDecValue={newValue => setAssetHVLParams({ marketLease: newValue })} className="w-100" />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Row className="align-items-center justify-content-between">
                            <Col xs={13}>
                                <h4 className="mb-0 text-black-50 inner_progress">
                                    Non-Recoverable
                                </h4>
                                <h6 style={{ color: '#797979' }}>in %</h6>
                            </Col>
                            <Col xs={11}>
                                <IncDecCounter incDecStep={0.25} min={0} max={1000} placeholder={DCF_RENT_ROLL_HVL_OTHER_TERM} value={otherCosts ? otherCosts : null} formatter={getFormattedDecimalNumber} setIncDecValue={newValue => setAssetHVLParams({ otherCosts: newValue })} className="w-100" />
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row gutter={24} className="mb-2 pt-2 align-items-center">
                    <Col xs={12}>
                        <Row className="align-items-center justify-content-between">
                            <Col xs={12}>
                                <h4 className="mb-0 text-black-50 inner_progress">
                                    Void Periods
                                </h4>
                                <h6 style={{ color: '#797979' }}>Months</h6>
                            </Col>
                            <Col xs={12}>
                                <IncDecCounter min={1} max={12} placeholder={DCF_RENT_ROLL_VOID_PERIOD_INITIAL} value={voidPeriodInitial} setIncDecValue={newValue => setAssetHVLParams({ voidPeriodInitial: newValue })} className="w-100" />
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12}>
                        <Row className="align-items-center justify-content-between">
                            <Col xs={13}>
                                <h4 className="mb-0 text-black-50 inner_progress">Tenant Improvements</h4>
                                <h6 style={{ color: '#797979' }}>Eur / SQM</h6>
                            </Col>
                            <Col xs={11}>
                                <IncDecCounter min={0} max={1000} placeholder={DCF_RENT_ROLL_TENANT_IMPROVEMENT_INITIAL} value={initialTenantImprovements} setIncDecValue={newValue => setAssetHVLParams({ initialTenantImprovements: newValue })} className="w-100" />
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <Row gutter={24} className="mb-2 pt-2 align-items-center">
                    <Col xs={12}>
                        <Row className="align-items-center justify-content-between">
                            <Col xs={12}>
                                <h4 className="mb-0 text-black-50 inner_progress">Renewal Probability</h4>
                                <h6 style={{ color: '#797979' }}>in %</h6>
                            </Col>
                            <Col xs={12}>
                                <IncDecCounter incDecStep={0.25} min={0} max={1000} placeholder={DCF_RENT_ROLL_INITIAL_RENEWAL_PROB} value={renewalProbability ? renewalProbability : null} formatter={getFormattedDecimalNumber} setIncDecValue={newValue => setAssetHVLParams({ renewalProbability: newValue })} className="w-100" />
                            </Col >
                        </Row >
                    </Col >
                    <Col xs={12}>
                        <Row className="align-items-center justify-content-between">
                            <Col xs={13}>
                                <h4 className="mb-0 text-black-50 inner_progress">
                                    Indexation
                                </h4>
                            </Col>
                            <Col xs={11}>
                                <div className="d-flex justify-content-between align-items-center"
                                    style={{
                                        border: '1px solid #E5E5E5',
                                        borderRadius: '4px',
                                        height: '40px'
                                    }}>
                                    <div style={{
                                        background: 'rgba(229, 229, 229, 0.4196078431)',
                                        height: '100%',
                                        padding: '8px',
                                        borderRadius: '4px 0 0 4px'
                                    }}>Off</div>
                                    <div> <Switch
                                        checked={isSwitch}
                                        onChange={switchChange}
                                    /></div>
                                    <div style={{
                                        background: 'rgba(229, 229, 229, 0.4196078431)',
                                        height: '100%',
                                        padding: '8px',
                                        borderRadius: '0 4px 4px 0'
                                    }}>On</div>
                                </div>
                                {/* <Select
                                    placeholder="Indexation?"
                                    allowClear
                                    value={
                                        indexationType
                                            ? indexationType
                                            : null
                                    }
                                    className="w-100"
                                    onChange={(e) => setAssetHVLParams({ indexationType: e })}
                                >
                                    {["Ja", "Nein"]?.map((item) => (
                                        <Select.Option key={item} value={item}>
                                            {item}
                                        </Select.Option>
                                    ))}
                                </Select> */}
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </Card >
        </div >
    );
}
export default VhlCashflowAssumption;
