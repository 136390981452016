/* eslint-disable */
import {
  Button,
  // Checkbox,
  Col,
  Divider,
  // Dropdown,
  Input,
  // Menu,
  Row,
  notification,
  Typography,
  Form,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
// import IMAGES from "../../../assets/images";
import DataTable from "../../../components/Layout/Table/DataTable";
import SearchForm from "./component/SearchForm";
import CreateUpdate from "./CreateUpdate";
import PATH from "../../../utils/path";
import DeleteUserModal from "../../../components/Layout/DeleteModel/DeleteUserModal";
import {
  deleteUsers,
  getAllUserList,
} from "../../../app/features/userManagement/user.slice.js";
import { FaFilter } from "react-icons/fa";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addUpdateUserModal, setAddUpdateUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  // const [cheacky, setCheacky] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [createdDateValue, setCreatedDateValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [flag, setFlag] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { users, isLoading } = useSelector((state) => state.userManagement);
  console.log("users", users)
  const { Search } = Input;
  const { Text } = Typography;
  const [userFilter, setUserFilter] = useState({
    search: "",
    date: "",
    email: "",
    location: "",
    pagination: {
      pageNo: 1,
      size: 10,
    },
  });
  const closeModal = () => {
    setIsModalOpen(false);
    setLocationValue("");
    setEmailValue("");
    setCreatedDateValue("");
    setSearchValue("");
    form.resetFields();
  };
  const closeAddUpdateUserModal = () => {
    setAddUpdateUserModal(false);
    setSelectedRowKeys([]);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  // Checkbox Row Selections
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const initialColState = [
    {
      id: 1,
      title: "UID",
      dataIndex: "userId",
      width: "10%",
    },
    {
      id: 2,
      hidden: false,
      title: "Date",
      dataIndex: "createdDate",
      width: "15%",
      render: (text) => moment(text).format("DD.MM.YYYY"),
    },
    {
      id: 3,
      hidden: false,
      title: "First Name",
      dataIndex: "firstName",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 4,
      hidden: false,
      title: "Last Name",
      dataIndex: "lastName",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 5,
      hidden: false,
      title: "Email",
      dataIndex: "email",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 10,
      hidden: user?.roleId === 2 ? false : true,
      title: "Role",
      dataIndex: "roleName",
      render: (text) => (text ? text : "N/A"),
    },
    {
      id: 6,
      hidden: false,
      title: "Location",
      dataIndex: "address",
      render: (text) => (
        <Text
          style={{
            width: 170,
          }}
          ellipsis={{ tooltip: { text } }}
        >
          {text ? text : "N/A"}
        </Text>
      ),
    },
  ];
  // const [columns, setColumns] = useState(initialColState);

  // Hide Columns
  // const handleChange = (e) => {
  //   if (e.target.checked) {
  //     setColumns((current) =>
  //       current.filter((val) => val.dataIndex !== e.target.value)
  //     );
  //     setCheacky((current) => [...current, e.target.value]);
  //   } else {
  //     setCheacky((current) =>
  //       current.filter((option) => option !== e.target.value)
  //     );
  //     setColumns((current) =>
  //       [
  //         ...current,
  //         { id: e.target.id, title: e.target.name, dataIndex: e.target.value },
  //       ].sort((a, b) => a.id - b.id)
  //     );
  //   }
  // };
  // const handleReset = () => {
  //   setColumns(initialColState);
  //   setCheacky([]);
  // };
  // const hideTableColumns = (
  //   <Menu
  //     items={[
  //       {
  //         key: 1,
  //         type: "group",
  //         label: (
  //           <div>
  //             <h4 style={{ color: "#000" }}>Hide table columns</h4>
  //           </div>
  //         ),
  //       },
  //       {
  //         key: 2,
  //         label: (
  //           <Checkbox
  //             value="createdDate"
  //             name="Date"
  //             id={2}
  //             checked={cheacky.includes("createdDate")}
  //             onChange={handleChange}
  //           >
  //             Date
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 3,
  //         label: (
  //           <Checkbox
  //             value="firstName"
  //             name="First Name"
  //             id={3}
  //             checked={cheacky.includes("firstName")}
  //             onChange={handleChange}
  //           >
  //             First Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 4,
  //         label: (
  //           <Checkbox
  //             value="lastName"
  //             name="Last Name"
  //             id={4}
  //             checked={cheacky.includes("lastName")}
  //             onChange={handleChange}
  //           >
  //             Last Name
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 5,
  //         label: (
  //           <Checkbox
  //             value="email"
  //             name="Email"
  //             id={5}
  //             checked={cheacky.includes("email")}
  //             onChange={handleChange}
  //           >
  //             Email
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 6,
  //         label: (
  //           <Checkbox
  //             value="address"
  //             name="Location"
  //             id={6}
  //             checked={cheacky.includes("address")}
  //             onChange={handleChange}
  //           >
  //             Location
  //           </Checkbox>
  //         ),
  //       },
  //       {
  //         key: 7,
  //         label: (
  //           <div>
  //             <hr style={{ border: "1px solid #F2F3F5" }} />
  //             <Button className="reset-dropdown-btn" onClick={handleReset}>
  //               Reset
  //             </Button>
  //           </div>
  //         ),
  //       },
  //     ]}
  //   />
  // );

  // Menu Open
  // const [open, setOpen] = useState(false);

  // const handleOpenChange = (flag) => {
  //   setOpen(flag);
  // };

  // Navigate Row

  
  const navigateRow = (record, rowIndex) => {
    const path = record; // to avoid es list error
    return {
      onClick: () => {
        navigate(
          PATH.USER_MANAGEMENT_USER_DETAIL.replace(":userId", path.userId)
        );
        console.log(record, rowIndex, path); // to avoid es lint error
      }, // click row
    };
  };

  // Api's
  // useEffect(() => {
  //   dispatch(getAllUserList(userFilter));
  // }, [dispatch, userFilter]);

  function notifyToaster(data, condition) {
    notification.config({
      maxCount: 1,
    });
    if (condition === "success") {
      notification.success({
        message: data,
      });
    } else {
      notification.error({
        message: data,
      });
    }
  }
  const moveToNextDeleteUser = () => {
    closeDeleteUserModal();
    setSelectedRowKeys([]);
    const filters = {
      search: "",
      date: "",
      email: "",
      location: "",
      pagination: {
        pageNo: 1,
        size: 10,
      },
    };
    setUserFilter(filters);
    // dispatch(getAllUserList(userFilter));
  };
  const deleteUser = () => {
    let finalData = [];
    finalData = selectedRowKeys.map((item) => item.userId);
    dispatch(deleteUsers({ finalData, moveToNextDeleteUser, notifyToaster }));
  };

  useEffect(() => {
    dispatch(
      getAllUserList({
        search: searchValue ? searchValue : "",
        date: createdDateValue
          ? moment(createdDateValue).format("YYYY-MM-DD")
          : "",
        email: emailValue ? emailValue : "",
        location: locationValue ? locationValue : "",
        pageNo: userFilter.pagination.pageNo,
        size: userFilter.pagination.size,
      })
    );
  }, [
    dispatch,
    searchValue,
    createdDateValue,
    emailValue,
    locationValue,
    userFilter,
  ]);

  const pageNumbers =
    userFilter.pagination.pageNo * userFilter.pagination.size -
    userFilter.pagination.size +
    1;

  const nextPageNumber =
    userFilter.pagination.pageNo * userFilter.pagination.size >
    users?.[0]?.totalRecords
      ? users?.[0]?.totalRecords
      : userFilter.pagination.pageNo * userFilter.pagination.size;

  return (
    <div className="user-management">
      <Row
        gutter={24}
        className="d-flex justify-content-between align-items-center button-search"
      >
        <Col xl={5} lg={5} md={12} sm={12} xs={24}>
          <div>
            <Button
              type="default"
              className="add-project-btn d-flex justify-content-between align-items-center new-user"
              onClick={() => setAddUpdateUserModal(true)}
              // disabled={selectedRowKeys?.length !== 0 ? "true" : false}
            >
              <span className="project-btn-span">
                <AiOutlinePlus size={30} />
              </span>
              <h5 className="mb-0 ml-3">New User</h5>
            </Button>
          </div>
        </Col>
        <Col
          xl={7}
          lg={7}
          md={12}
          sm={12}
          xs={24}
          className="mt-2 mt-xs-2 mt-lg-0 w-100"
        >
          <div className="d-flex searchbar-project">
            <span className="searchbar-icon">
              <FiSearch size={20} />
            </span>
            <Search
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSearchValue(e.target.value);
                }
              }}
              onChange={(e) => {
                if (e.key === "Enter") {
                  setSearchValue(e.target.value);
                }
              }}
              // onChange={handleSearch}
              className="mr-2"
              placeholder="Search a User"
            />
            <span
              onClick={() => setIsModalOpen(true)}
              className="filter-icon-span mr-2 cursor-pointer"
            >
              <FaFilter />
            </span>
            {/* <Dropdown
              overlay={hideTableColumns}
              trigger={["click"]}
              onOpenChange={handleOpenChange}
              open={open}
            >
              <span className="filter-icon-span cursor-pointer">
                <img src={IMAGES.TABLE_ICON} alt="Table Icon" />
              </span>
            </Dropdown> */}
          </div>
        </Col>
      </Row>
      <div>
        <DataTable
          columns={initialColState.filter((item) => item.hidden === false)}
          dataSource={users}
          pagination={{
            current: userFilter?.pagination?.pageNo || 1,
            pageSize: 10,
            total: users?.[0]?.totalRecords,
            showSizeChanger: false,
            disabled: false,
            onChange: (page, pageSize) => {
              setUserFilter({
                ...userFilter,
                pagination: {
                  pageNo: page,
                  size: pageSize,
                },
              });
            },
          }}
          className="antd-table-striped"
          loading={isLoading}
          rowSelection={rowSelection}
          rowKey={(record) => record}
          onRow={navigateRow}
          scroll={{
            x: 1000,
            y: "calc(100vh - 355px)",
          }}
        />
      </div>
      {users?.length > 0 && (
        <>
          <div className="table-count">
            <h5 className="table-count-content">
              Showing {pageNumbers} to {nextPageNumber} of{" "}
              {users?.[0]?.totalRecords} entries
            </h5>
          </div>

          <Row gutter={24}>
            <Col
              xs={24}
              style={{
                padding: 0,
              }}
            >
              <div className="table-footer-btn d-flex align-items-center">
                <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
                  <Button
                    className="btn-action d-flex justify-content-center align-items-center text-danger"
                    onClick={() => setDeleteUserModal(true)}
                    disabled={selectedRowKeys?.length === 0 ? "true" : false}
                  >
                    <div className="active-action d-flex align-items-center">
                      <MdDelete />
                      <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                    </div>
                  </Button>
                  {selectedRowKeys?.length > 1 ? (
                    ""
                  ) : (
                    <>
                      <Divider type="vertical" />
                      <Button
                        className="btn-action d-flex justify-content-center align-items-center"
                        onClick={() => {
                          setFlag(true);
                          setAddUpdateUserModal(true);
                        }}
                        disabled={
                          selectedRowKeys?.length === 0 ? "true" : false
                        }
                      >
                        <div className="active-action d-flex align-items-center">
                          <MdModeEdit className="mt-1" />
                          <h5 className="mb-0 ml-1 mt-1 ">Edit</h5>
                        </div>
                      </Button>
                    </>
                  )}
                </div>

                {/* <Button className="btn-cancel py-2">
              <h5>Cancel</h5>
            </Button> */}
              </div>
            </Col>
          </Row>
        </>
      )}
      <SearchForm
        visible={isModalOpen}
        handleCancel={closeModal}
        setCreatedDateValue={setCreatedDateValue}
        setEmailValue={setEmailValue}
        setLocationValue={setLocationValue}
        setSearchValue={setSearchValue}
        setIsModalOpen={setIsModalOpen}
        form={form}
      />

      <CreateUpdate
        visible={addUpdateUserModal}
        handleCancel={closeAddUpdateUserModal}
        selectedRowKeys={selectedRowKeys}
        isLoaded={isLoading}
        setSelectedRowKeys={setSelectedRowKeys}
        flag={flag}
        setFlag={setFlag}
        userFilter={userFilter}
      />

      <DeleteUserModal
        visible={deleteUserModal}
        handleCancel={closeDeleteUserModal}
        onSubmit={deleteUser}
        isLoaded={isLoading}
        user="User"
      />
    </div>
  );
}
