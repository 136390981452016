/* eslint-disable*/
import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import {
    Button,
    Form,
    Checkbox,
    Menu,
    Col,
    Row,
} from "antd";
import DataTable from "../../../../../../../components/Layout/Table/DataTable";
import DeleteUserModal from "../../../../../../../components/Layout/DeleteModel/DeleteUserModal";
import CreateUpdateRentRoll from "../../CreateUpdateRentRoll";
import { useParams } from "react-router-dom";
import {
    updateVisibleColumns,
} from "../../../../../../../app/features/Lookups/lookup.slice";
import {
    deleteRentRolls,
    getRentRollsByAsset,
    updateRentRoll,
} from "../../../../../../../app/features/RentRoll/rentRoll.slice";
import { useDispatch, useSelector } from "react-redux";
import { RENT_ROLLS_PAGE_SIZE } from "../../../../../../../utils/helpers";
import { getCalculationDetail } from "../../../../../../../app/features/calculation/calculationAsset.slice";
import { IoMdClose } from "react-icons/io";

function RentRollsTable({
    sethideTableColumns,
    activeKey,
    loadRentRolls,
    searchRentRoll,
    assetDetails,
    columnSelect,
    tableColumns,
    setRentRollsColumns, rentRollsColumns,
    hideableColumns,
    editableCell,
    loadRentRollsByAsset,
    calculationRentRolls,
    setRentRollsDataByAsset,
    notifyToaster,
    editingKey,
    setEditingKey,
    isEditing,
    prepareEditValues,
    prepareUpdatedRentRoll,
    selectedRowKeys, setSelectedRowKeys,
    rentRollsByAsset,
    // isCalculationDetailLoading,
    rentRollDates,
    calculationDetail,
}) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const param = useParams();
    const assetId = param.assetDetailId;
    // const [addUpdateNewRentRoll, setAddUpdateNewRentRoll] = useState(false);
    // const [deleteUserModal, setDeleteUserModal] = useState(false);
    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [moduleIdValue, setModuleIdValue] = useState("");
    const [moduleTabIdValue, setModuleTabIdValue] = useState("");
    const [toggle, setToggle] = useState([]);
    const getRentRollsHideColumns = localStorage.getItem('rentRollsHideColumns');
    const rentRollsHideColumns = JSON.parse(getRentRollsHideColumns);

    const checkboxfinalData = rentRollsHideColumns ?
        rentRollsHideColumns?.filter(item => item?.filter?.includes(columnSelect))
        : hideableColumns?.filter(item => item?.filter?.includes(columnSelect));

    const [checkBoxData, setCheckBoxData] = useState(checkboxfinalData);

    // console.log(checkboxfinalData, 'checkboxfinalData')
    useEffect(() => {
        setCheckBoxData(checkboxfinalData);
    }, [columnSelect, JSON.stringify(checkBoxData)])

    //const { rentRollsByAsset, isLoading } = assetDetails; 
    // const { isCalculationDetailLoading, calculationDetail, calculationModified } = useSelector((state) => state.calculation);
    // const rentRollsByAsset = calculationDetail ? calculationDetail.rentRolls ? Object.values(calculationDetail.rentRolls) : [] : null
    // const { importExcelFileRentRollsLoading } = useSelector(state => state.settings)
    // const [curentPage, setCurrentPage] = useState(1)
    // const [curentPageSize, setCurrentPageSize] = useState(RENT_ROLLS_PAGE_SIZE)

    //const { rentRollsByAsset, isLoading } = assetDetails;
    const { isCalculationDetailLoading,
        // calculationDetail,
        calculationModified } = useSelector((state) => state.calculation);
    // const rentRollsByAsset = calculationDetail ? calculationDetail.rentRolls ? Object.values(calculationDetail.rentRolls) : [] : null
    const { importExcelFileRentRollsLoading } = useSelector(state => state.settings)
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageSize, setCurrentPageSize] = useState(RENT_ROLLS_PAGE_SIZE)

    const AddUpdateNewRentRollcloseModal = () => {
        setAddUpdateNewRentRoll(false);
    };

    const closeDeleteUserModal = () => {
        setDeleteUserModal(false);
    };
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const edit = (record) => {
        console.log(record, 'record rent roll')
        form.setFieldsValue({
            ...record,
        });
        prepareEditValues(record, form)
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const moveToNext = () => {
        setEditingKey("");
        const finalData = {
            assetId: +assetId,
        };
        dispatch(getCalculationDetail({ finalData, notifyToaster, autoSaveCalc: true }));
    };
    const moveToNext2 = () => {
        setEditingKey("");
        let hideBtn = document.querySelectorAll(".hide-btn")[0];
        let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
        hideBtn.disabled = false;
        hideBtn2.disabled = false;
        const finalData = {
            assetId: +assetId
        };
        dispatch(getCalculationDetail({ finalData, notifyToaster }));
    };

    // const save = async (record) => {
    //     const row = await form.validateFields();
    //     const finalData = prepareUpdatedRentRoll(record, row);
    //     if (finalData)
    //         dispatch(updateRentRoll({ finalData, moveToNext, notifyToaster }));
    // };


    const save = useCallback(async (record) => {
        try {
            const row = await form.validateFields();
            const finalData = prepareUpdatedRentRoll(record, row, rentRollDates);
            dispatch(updateRentRoll({ finalData, moveToNext, notifyToaster }));
            setEditingKey("");
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
        finally {
            const finalData = {
                assetId: +assetId
            };
            dispatch(getCalculationDetail({ finalData, notifyToaster }));
            setRentRollsDataByAsset(calculationRentRolls)
        }
    }, [form, rentRollDates, prepareUpdatedRentRoll]);

    const moveToNextDelete = () => {
        closeDeleteUserModal();
        setSelectedRowKeys([]);
        setEditingKey("");
        const finalData = {
            assetId: +assetId
        };
        dispatch(getCalculationDetail({ finalData, notifyToaster, autoSaveCalc: true }));
    };
    const deleteProjectDetail = () => {
        const finalData = selectedRowKeys;
        dispatch(deleteRentRolls({ finalData, moveToNextDelete, notifyToaster }));
    };

    const MyCheckbox = ({ value, name, isChecked, handleChange }) => {
        return (
            <Checkbox
                value={value}
                name={name}
                checked={isChecked}
                onChange={handleChange}
            >
                {name}
            </Checkbox>
        );
    };

    const actionColumn = [{
        id: 10,
        title: "Action",
        dataIndex: "Action",
        hidden: false,
        children: [{
            title: '',
            children: [{
                id: 1,
                title: '',
                width: "80px",
                fixed: 'right',
                align: 'center',
                render: (_, record) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <span className="d-flex align-items-center justify-content-center">
                            <div
                                role="presentation"
                                className="m-0 mr-2 drawer-action-icons cursor-pointer"
                                onClick={() => cancel()}
                            >
                                <IoMdClose size={20} />
                            </div>
                            <div
                                className="m-0 drawer-action-icons cursor-pointer"
                                role="presentation"
                                onClick={() => save(record)}
                            >
                                <FaCheck size={20} />
                            </div>
                        </span>
                    ) : (
                        <div className="d-flex align-items-center justify-content-center">
                            <div
                                className="drawer-action-icons d-flex align-items-center cursor-pointer"
                                role="presentation"
                                disabled={editingKey !== ""}
                                onClick={() => edit(record)}
                            >
                                <MdModeEdit size={20} />
                            </div>
                        </div>
                    );
                },
            }]
        }]
    }]

    // const allColumns = tableColumns.concat(actionColumn);


    // const [checkBoxData, setCheckBoxData] = useState(hideableColumns);




    useEffect(() => {
        sethideTableColumns(<Menu items={checkBoxData}></Menu>);
    }, [JSON.stringify(checkBoxData)]);

    const handleChange = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;

        // Update the checked status in checkBoxData
        const updatedCheckBoxData = checkBoxData.map((item) => {
            if (item.value === value) {
                item.isChecked = checked;
            }
            return item;
        });

        // Update checkBoxData state
        setCheckBoxData(updatedCheckBoxData);
        localStorage.setItem('rentRollsHideColumns', JSON.stringify(updatedCheckBoxData));

        // Get the values of checked checkboxes
        const columnsToKeep = updatedCheckBoxData
            .filter((item) => item.isChecked)
            .map((item) => item.value);

        // Filter columns based on checked checkboxes
        const filterColumns = (columns) => {
            const filterRecursive = (columns) => {
                return columns.reduce((acc, column) => {
                    if (column.children && column.children.length > 0) {
                        const filteredChildren = filterRecursive(column.children);
                        const childDataIndexes = column.children.map(child => child.dataIndex);
                        const shouldHideParent = childDataIndexes.every(dataIndex => columnsToKeep.includes(dataIndex));

                        if (filteredChildren.length > 0 || !shouldHideParent) {
                            acc.push({ ...column, children: filteredChildren });
                        }
                    } else {
                        const columnDataIndexes = Array.isArray(column.dataIndex) ? column.dataIndex : [column.dataIndex];
                        if (!columnDataIndexes.some(dataIndex => columnsToKeep.includes(dataIndex))) {
                            acc.push(column);
                        }
                    }
                    return acc;
                }, []);
            };

            const filteredColumns = filterRecursive(columns);
            const finalColumns = filteredColumns.filter(column => !('children' in column && column.children.length === 0));
            return finalColumns.filter(item => item?.filter?.includes(columnSelect));
        };

        // Filter columns and update state
        const filteredColumns = tableColumns?.filter(item => item?.filter?.includes(columnSelect))
        const filteredArray = filterColumns(filteredColumns);
        setRentRollsColumns(filteredArray);
    };

    const handleRemove = (checkBoxData) => {
        let data;
        let finalPayload;
        let hideBtn = document.querySelectorAll(".hide-btn")[0];
        let hideBtn2 = document.querySelectorAll(".hide-btn2")[0];
        const checkBox = [];
        checkBoxData.map((cb) => {
            if (cb.isChecked === false && cb.key !== 1 && cb.key !== 9) {
                checkBox.push(cb.value);
                let obj = {
                    moduleId: moduleIdValue,
                    moduleTabId: moduleTabIdValue,
                    columnName: checkBox,
                };

                data = obj;
            }
            let _obj = { moduleId: moduleIdValue, moduleTabId: moduleTabIdValue };
            finalPayload = checkBox.length === 0 ? _obj : data;
        });
        dispatch(
            updateVisibleColumns({
                finalData: finalPayload,
                moveToNext2,
                notifyToaster,
            })
        );
        hideBtn.disabled = true;
        hideBtn2.disabled = true;
    };

    const handleReset = () => {
        const uCD = checkBoxData.map((item) => {
            item.isChecked = false;
            return item;
        });
        setCheckBoxData(uCD);
        handleRemove(uCD);
    };

    useEffect(() => {
        const cBD = checkBoxData?.map((checkbox) => {
            return checkbox?.isChecked?.toString();
        });
        checkBoxData.map((checkbox) => {
            let key = checkbox?.key;
            if (key == 1) {
                checkbox.label = (
                    <div>
                        <h4 style={{ color: "#000" }}>Hide table columns</h4>
                    </div>
                );
            } else if (key == 45) {
                checkbox.label = (
                    <div>
                        <hr style={{ border: "1px solid #F2F3F5" }} />
                        <div className="d-flex justify-content-evenly">
                            <Button
                                disabled={!cBD.includes("true")}
                                style={{ width: "130px" }}
                                className="reset-dropdown-btn hide-btn2"
                                onClick={handleReset}
                            >
                                Reset
                            </Button>{" "}
                            <Button
                                disabled={!cBD.includes("true")}
                                style={{ width: "130px" }}
                                className="reset-dropdown-btn hide-btn"
                                onClick={() => handleRemove(checkBoxData)}
                            >
                                hide Column
                            </Button>
                        </div>
                    </div>
                );
            } else {
                checkbox.label = (
                    <MyCheckbox
                        value={checkbox.value}
                        name={checkbox.name}
                        isChecked={checkbox.isChecked}
                        handleChange={handleChange}
                    />
                );
            }
        });
    }, [JSON.stringify(checkBoxData)]);

    useEffect(() => {
        const columnsToKeep = rentRollsHideColumns
            ?.filter((item) => item.isChecked)
            .map((item) => item.value);
        // Filter columns based on checked checkboxes
        const filterColumns = (columns) => {
            const filterRecursive = (columns) => {
                return columns.reduce((acc, column) => {
                    if (column?.children && column?.children.length > 0) {
                        const filteredChildren = filterRecursive(column?.children);
                        const childDataIndexes = column?.children?.map(child => child?.dataIndex);
                        const shouldHideParent = childDataIndexes?.every(dataIndex => columnsToKeep?.includes(dataIndex));

                        if (filteredChildren.length > 0 || !shouldHideParent) {
                            acc.push({ ...column, children: filteredChildren });
                        }
                    } else {
                        const columnDataIndexes = Array.isArray(column?.dataIndex) ? column?.dataIndex : [column?.dataIndex];
                        if (!columnDataIndexes?.some(dataIndex => columnsToKeep?.includes(dataIndex))) {
                            acc.push(column);
                        }
                    }
                    return acc;
                }, []);
            };

            const filteredColumns = filterRecursive(columns);
            const finalColumns = filteredColumns.filter(column => !('children' in column && column.children.length === 0));
            return finalColumns.filter(item => item?.filter?.includes(columnSelect));
        };

        // Filter columns and update state
        const filteredColumns = tableColumns?.filter(item => item?.filter?.includes(columnSelect))
        const filteredArray = filterColumns(filteredColumns);
        setRentRollsColumns(filteredArray);
    }, [JSON.stringify(checkBoxData)])

    const filterColumns = (columns) => {
        const columnsToKeep = rentRollsHideColumns
            ?.filter((item) => item.isChecked)
            .map((item) => item.value);

        const filterRecursive = (columns) => {
            return columns.reduce((acc, column) => {
                if (column.children && column.children.length > 0) {
                    const filteredChildren = filterRecursive(column.children);
                    const childDataIndexes = column.children.map(child => child.dataIndex);
                    const shouldHideParent = childDataIndexes.every(dataIndex => columnsToKeep?.includes(dataIndex));

                    if (filteredChildren.length > 0 || !shouldHideParent) {
                        acc.push({ ...column, children: filteredChildren });
                    }
                } else {
                    const columnDataIndexes = Array.isArray(column.dataIndex) ? column.dataIndex : [column.dataIndex];
                    if (!columnDataIndexes.some(dataIndex => columnsToKeep?.includes(dataIndex))) {
                        acc.push(column);
                    }
                }
                return acc;
            }, []);
        };

        const filteredColumns = filterRecursive(columns);
        // Filter out columns with no children
        const finalColumns = filteredColumns.filter(column => !('children' in column && column.children.length === 0));
        return finalColumns.filter(item => item?.filter?.includes(columnSelect));
    };

    const formData = rentRollsByAsset?.length > 0 ? [...rentRollsByAsset]?.reverse() : [];
    const allColumns = filterColumns(tableColumns).concat(actionColumn);
    return (
        <>
            <div className="rentroll-table">
                <Form form={form} component={false}>
                    <DataTable
                        components={{
                            body: {
                                cell: editableCell,
                            },
                        }}
                        rowClassName={() => "editable-row"}
                        columns={allColumns.filter((item) => item.hidden === false)}
                        dataSource={formData}
                        pagination={{
                            current: currentPage,
                            pageSize: currentPageSize,
                            total: rentRollsByAsset?.length,
                            showSizeChanger: false,
                            disabled: false,
                            onChange: (page, pageSize) => {
                                // const filter = {
                                //     ...rentRollFilter,
                                //     pagination: {
                                //         page: page,
                                //         size: pageSize,
                                //     }
                                // }
                                // loadRentRolls(filter);
                                setCurrentPage(page);
                                setCurrentPageSize(pageSize);
                            },
                        }}
                        className="rent-roll-custom-table antd-table-striped"
                        rowSelection={rowSelection}
                        loading={isCalculationDetailLoading || importExcelFileRentRollsLoading}
                        rowKey={(record) => record.id}
                        scroll={{
                            x: 900,
                            y: "calc(100vh - 472px)",
                        }}
                    />
                </Form>
            </div>
            {rentRollsByAsset?.length > 0 && (
                <>
                    <div className="table-count">
                        <h5 className="table-count-content">
                            Showing {currentPage * currentPageSize - currentPageSize + 1} to {currentPage * currentPageSize > rentRollsByAsset?.length ? rentRollsByAsset?.length
                                : currentPage * currentPageSize} of {rentRollsByAsset?.length} entries
                        </h5 >
                    </div >
                    {/* <Row gutter={24} className="ml-0">
                        <Col
                            xs={24}
                            style={{
                                padding: 0,
                            }}
                        >
                            <div className="table-footer-btn d-flex align-items-center">
                                <div className="align-items-center bg-white d-flex justify-content-between rounded-sm btn-action-div p-1">
                                    <Button
                                        className="btn-action d-flex justify-content-center align-items-center text-danger"
                                        onClick={() => setDeleteUserModal(true)}
                                        disabled={selectedRowKeys?.length === 0 ? "true" : false}
                                    >
                                        <div className="active-action d-flex align-items-center">
                                            <MdDelete />
                                            <h5 className="mb-0 ml-1 mt-1 text-danger">Delete</h5>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                </>
            )
            }
            {/* <DeleteUserModal
                visible={deleteUserModal}
                handleCancel={closeDeleteUserModal}
                user="Asset"
                onSubmit={deleteProjectDetail}
                isLoaded={isCalculationDetailLoading}
            />
            <CreateUpdateRentRoll
                visible={addUpdateNewRentRoll}
                handleCancel={AddUpdateNewRentRollcloseModal}
            /> */}
        </>
    );
}
export default RentRollsTable;

// EditableCell.propTypes = {
//   editing: PropTypes.node.isRequired,
//   dataIndex: PropTypes.node.isRequired,
//   title: PropTypes.node.isRequired,
//   inputType: PropTypes.node.isRequired,
//   record: PropTypes.node.isRequired,
//   index: PropTypes.node.isRequired,
//   children: PropTypes.node.isRequired,
// };

RentRollsTable.propTypes = {
    sethideTableColumns: PropTypes.node.isRequired,
    activeKey: PropTypes.node.isRequired,
    searchRentRoll: PropTypes.node.isRequired,
    assetDetails: PropTypes.object.isRequired,
    tableColumns: PropTypes.array.isRequired,
    hideableColumns: PropTypes.array.isRequired,
    editableCell: PropTypes.func.isRequired,
    notifyToaster: PropTypes.func.isRequired,
    setEditingKey: PropTypes.func.isRequired,
    editingKey: PropTypes.string.isRequired,
    isEditing: PropTypes.func.isRequired,
    prepareEditValues: PropTypes.func.isRequired,
    prepareUpdatedRentRoll: PropTypes.func.isRequired,
};
