/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import { Card, Form, Input, Table, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ImBin2 } from "react-icons/im";
import { AiOutlinePlus } from "react-icons/ai";
import { PageCalculationDetailDto, PortfolioDto } from "reia-rest-client";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedEnglishNumber, getFormattedInt } from "../../../../../../../utils/digitHelper";
import moment from "moment";
import { CalculationDetail } from "reia-rest-client";
import { CalculationDetailDto } from "reia-rest-client";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Sticky from "react-stickynode";
import { getPortfolioAssetValue } from "../../../../../../../utils/portfolioHelper";


export default function App({ handleToggleDrawer, PortfolioSelect, showDrawer }) {
  const dispatch = useDispatch();

  const { portfolio, calculationDetailsByPortfolio, dcfResultsModified, isLoading, isSuccess }: {
    portfolio: PortfolioDto,
    calculationDetailsByPortfolio: PageCalculationDetailDto
  } = useSelector((state) => state.portfolio);

  const [otherCFs, setOtherCFs] = useState([])
  const assetValue = getPortfolioAssetValue(calculationDetailsByPortfolio?.calculationDetailDtos, dcfResultsModified);
  useEffect(() => {

    if (calculationDetailsByPortfolio?.calculationDetailDtos?.length > 0) {
      const allOtherCFs = calculationDetailsByPortfolio.calculationDetailDtos.map((calcDetail: CalculationDetailDto) =>
        calcDetail.assetDCFParams.otherIncomeCosts.map(otherIncomeCost => {
          return { assetName: calcDetail.assetName + " (ID: " + calcDetail.assetId + ")", ...otherIncomeCost }
        })).flat();

      setOtherCFs(allOtherCFs)
    }

  }, [calculationDetailsByPortfolio])

  const groundRentColumns = [
    {
      title: "Asset Name/ID",
      dataIndex: "assetName",
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      editable: true,
    },
    {
      title: "Amount (€)",
      dataIndex: "value",
      editable: true,
      render: (text) => getFormattedInt(text) + " €"
    },
    {
      title: "Type",
      dataIndex: "type",
      editable: true,
    },
    {
      title: "Rotation",
      dataIndex: "rotation",
      editable: true,
    },
    {
      title: "Start Date",
      dataIndex: "start",
      editable: true,
      render: (text) => (text && moment(text).format("DD.MM.YYYY")) || "N/A"
    },
    {
      title: "End Date",
      dataIndex: "end",
      editable: true,
      render: (text) => (text && moment(text).format("DD.MM.YYYY")) || "N/A"
    }
  ];

  const columns = groundRentColumns.map((col) => {
    return col;
  });


  return (
    <div className="incomeAndCost site-card-border-less-wrapper">
      <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs">
        <div style={{
          display: "flex", justifyContent: "space-between", flexWrap: 'wrap',
          alignItems: "center", padding: '10px', background: '#fff',
          border: '1.5px solid #b0bacd', borderRadius: '8px', marginBottom: '20px',
        }}
        >
          <div className="d-flex align-items-center" style={{gap:'10px'}}>
            <h3 className="mb-0">Other Costs</h3>
            {!showDrawer &&
              <h4 className="mb-0"> Portfolio Value: {getFormattedEnglishNumber(assetValue)} €</h4>
            }
          </div>
          <div className="d-flex align-items-center">
            {PortfolioSelect && <PortfolioSelect />}
            <Tooltip
              title="show portfolio detail"
              color="#00215B"
              key="#00215B"
            >
              <span
                className={`project-drawer-close-btn cursor-pointer mr-2 ml-2 ${showDrawer ? "d-none" : ""
                  }`}
                role="button"
                tabIndex="0" onClick={handleToggleDrawer}
              >
                <MdOutlineKeyboardArrowLeft
                  size={25}
                  fill="#fff"

                />
              </span>
            </Tooltip>
          </div>
        </div>
      </Sticky >
      <Table
        rowClassName={() => "editable-row"}
        columns={columns}
        dataSource={otherCFs}
        pagination={false}
        className="antd-custom-card-table antd-table-striped"
        scroll={{
          y: 610,
        }}
      />
    </div>
  );
}