import { Button, Col, Row, Tabs, Tooltip } from "antd";
import { LuRefreshCw } from "react-icons/lu";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useSelector } from "react-redux";
import Sticky from "react-stickynode";
import IMAGES from "../../../../../assets/images";
import { getFormattedEnglishNumber } from "../../../../../utils/digitHelper";
import VeAcquisitionCosts from "./component/Calculation/ValuationExpert/VeAcquisitionCosts";
import VeAcquisitionCostsExit from "./component/Calculation/ValuationExpert/VeAcquisitionCostsExit";
import VeAssetBaseRentType from "./component/Calculation/ValuationExpert/VeAssetBaseRentType";
import VeAssetValue from "./component/Calculation/ValuationExpert/VeAssetValue";
import VeAssumptionsParameter from "./component/Calculation/ValuationExpert/VeAssumptionsParameter";
import VeCapitalizeRate from "./component/Calculation/ValuationExpert/VeCapitalizeRate";
import VeFinancingAssumption from "./component/Calculation/ValuationExpert/VeFinancingAssumption";
import VeInflationAndERVGrowth from "./component/Calculation/ValuationExpert/VeInflationAndERVGrowth";
import VhlAreaLeaseInformation from "./component/Calculation/ValuationHighLevel/VhlAreaLeaseInformation";
import VhlCashflowAssumption from "./component/Calculation/ValuationHighLevel/VhlCashflowAssumption";
import VhlInflationAndERVGrowth from "./component/Calculation/ValuationHighLevel/VhlInflationAndERVGrowth";

function Calculation({ allRentRolls, autoSaveCalc, allDataLoaded, isCalcLoading, handleToggleDrawer, showDrawer, AssetSelect,
  calculationModified, kpisModified, assumptionsModified, assetValueMismatched, calculationDetail, saveCalculationDetails, resetCalculationDetail }) {

  const { assetDCFResult } = useSelector(state => state.calculation)
  const newNetAssetValue = assetDCFResult?.dcfResult?.assetCashFlow?.netAssetValue;


  const disableActions = !autoSaveCalc && allDataLoaded() && !isCalcLoading() && (calculationModified || assetValueMismatched || kpisModified || assumptionsModified || !calculationDetail?.assetDCFResult?.dcfResult);
  // ************
  const renderTabBar: TabsProps["renderTabBar"] = (props, DefaultTabBar) => (
    <Sticky enabled={true} top={109} innerZ={10} innerActiveClass="stickyTabs">
      {/* 160 */}
      <div style={{
        display: "flex", justifyContent: "space-between", flexWrap: 'wrap',gap:'10px',
        alignItems: "center", padding: '10px', marginBottom: '20px', background: '#fff',
        border: '1.5px solid #b0bacd', borderRadius: '8px'
      }}
      >
        <div className="d-flex align-items-center">
          <DefaultTabBar {...props} style={{ display: 'flex' }} />
          {!showDrawer &&
            <h4 className="mb-0"> Asset Value: {getFormattedEnglishNumber(newNetAssetValue)} €</h4>
          }
        </div>
        <div className="d-flex align-items-center">
          <>
            <Tooltip title="Save changes" color="#00215B" key="save-changes">
              <div>
                <Button
                  icon={< IMAGES.SAVE_ICON />}
                  className="save-icon-btn mr-2 cursor-pointer"
                  onClick={saveCalculationDetails}
                  disabled={!disableActions}
                />
              </div>
            </Tooltip>

            <Tooltip title="Reset changes" color="#00215B" key="reset-changes">
              <div>
                <Button
                  icon={<LuRefreshCw size={24} />}
                  className="reset-icon-btn mr-2 cursor-pointer"
                  onClick={resetCalculationDetail}
                  disabled={!disableActions}
                />
              </div>
            </Tooltip>
          </>
          {AssetSelect && <AssetSelect />}
          <Tooltip
            title="show asset detail"
            color="#00215B"
            key="#00215B"
          >
            {/* drawer-close-btn */}
            <span
              className={`project-drawer-close-btn cursor-pointer mr-2 ml-2 ${showDrawer ? "d-none" : ""
                }`}
              role="button"
              tabIndex="0" onClick={handleToggleDrawer}
            >
              <MdOutlineKeyboardArrowLeft
                size={25}
                fill="#fff"
              />
            </span>
          </Tooltip>
        </div>
      </div>
    </Sticky >
  );

  return (
    <>
      <div className="asset-details-calculation">
        <Tabs
          type="card"
          renderTabBar={renderTabBar}
          activeKey={allRentRolls && allRentRolls?.length > 0 ? "1" : "2"}
        >
          <Tabs.TabPane tab="Expert Mode" key="1" className="tab1">
            <div className="calculationComponent">
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <VeAssetValue calculationDetail={calculationDetail} allDataLoaded={allDataLoaded}/>
                </Col>
                <Col xs={24} md={12}>
                  <VeCapitalizeRate />
                  <VeAssetBaseRentType />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <VeAcquisitionCosts />
                </Col>
                <Col xs={24} lg={12}>
                  <VeAcquisitionCostsExit />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <VeInflationAndERVGrowth mode="inflation" />
                </Col>
                <Col xs={24} md={12}>
                  <VeInflationAndERVGrowth mode="erv" />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <VeFinancingAssumption fullWidth={true} />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <VeAssumptionsParameter />
                </Col>
              </Row>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="High Level" key="2" className="tab2">
            <div className="calculationComponent">
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <VeAssetValue calculationDetail={calculationDetail} />
                </Col>
                <Col xs={24} md={12}>
                  <VhlAreaLeaseInformation />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <VhlCashflowAssumption />
                </Col>
                <Col xs={24} md={12}>
                  <VeCapitalizeRate />
                  <VeAssetBaseRentType />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <VeAcquisitionCosts extraPadding={true} />
                </Col>
                <Col xs={24} md={12}>
                  <VeFinancingAssumption fullWidth={false} />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <VhlInflationAndERVGrowth />
                </Col>
              </Row>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
}

export default Calculation;
